import React from "react";
import PropTypes from "prop-types";
// @material-ui/icons
import CloseIcon from "@material-ui/icons/Close";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "../../Grid/GridContainer.jsx";
import GridItem from "../../Grid/GridItem.jsx";

const style = {
    files: {
        listStyleType: "none",
        padding: "0px",
        "& li": {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "5px 10px",
            "&:hover": {
                backgroundColor: "#eee"
            },
            "& svg": {
                cursor: "pointer"
            }
        }
    },
    infoText: {
        fontWeight: "300",
        margin: "10px 0 15px",
        textAlign: "center",
        fontSize: "14px"
    },
};

class Documents extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            files: props.files
        };
    }
    sendState() {
        return this.state;
    }
    onFileRemove(file){
        const { files } = this.state;
        let newFiles = [];
        files.map(newFile => {
            if(newFile.id !== file.id){
                newFiles.push(newFile);
            }
            return null;
        });
        this.setState({
            files: newFiles
        });
    }
    isValidated(){
        return this.state.files.length > 0;
    }
    render() {
        const { classes } = this.props;
        const { files } = this.state;
        return (
            <GridContainer justify="center">
                <GridItem xs={12} sm={12}>
                    <h4 className={classes.infoText}>Review Documents</h4>
                    <ul className={classes.files}>
                        {
                            files.map((file, key) => {
                                return (
                                    <li key={key}>
                                        <span>{file.name}</span>
                                        <CloseIcon onClick={() => this.onFileRemove(file)} />
                                    </li>
                                )
                            })
                        }
                    </ul>
                </GridItem>
            </GridContainer>
        );
    }
}

Documents.propTypes = {
  classes: PropTypes.object
};

export default withStyles(style)(Documents);
