import {
    whiteColor,
    grayColor,
    infoColor,
    dangerColor,
    tableHeadColumns,
} from "../material-kit-pro-react.jsx";
import userHomePageStyle from "./userHomePageStyle.jsx";
import modalStyle from "../material-kit-pro-react/modalStyle.jsx";
import customCheckboxRadioSwitchStyle from "../material-kit-pro-react/customCheckboxRadioSwitchStyle.jsx";  
const activitiesModalStyle = theme => ({
    ...userHomePageStyle(theme),
    ...customCheckboxRadioSwitchStyle,
    ...modalStyle(theme),
    textCenter: {
        textAlign: "center"
    },
    justifyContentCenter: {
        WebkitBoxPack: "center !important",
        MsFlexPack: "center !important",
        justifyContent: "center !important",
        alignSelf: "center !important"
    },
    justifyContentBetween: {
        WebkitBoxPack: "space-between !important",
        MsFlexPack: "space-between !important",
        justifyContent: "space-between !important",
        alignSelf: "space-between !important"
    },
    modal: {
        ...modalStyle(theme).modal,
        maxWidth: "1050px !important",
        paddingRight: "1em",
        paddingLeft: "1em",
        backgroundColor: whiteColor,
        marginTop: "20px !important"
    },
    modalBody: {
        ...modalStyle(theme).modalBody,
        paddingBottom: "0px",
        paddingTop: "0px",
        paddingRight: "0px",
        paddingLeft: "0px",
        "& .sd_loader": {
            width: "300px !important",
            height: "300px !important",
            [theme.breakpoints.down("xs")]: {
                width: "180px !important",
                height: "180px !important",
            },
            "& > div": {
                width: "250px !important",
                height: "250px !important",
                [theme.breakpoints.down("xs")]: {
                    width: "150px !important",
                    height: "150px !important",
                },
            },
            "& svg": {
                "& circle": {
                    strokeWidth: "1px"
                }
            }
        }
    },
    modalFooter: {
        ...modalStyle(theme).modalFooter,
        marginTop: theme.spacing(2),
        padding: "0px",
        paddingBottom: "15px",
        justifyContent: "space-between !important",
        MsFlexPack: "space-between !important",
        WebkitBoxPack: "space-between !important",
    },
    errorMessage: {
        color: dangerColor[0]
    },
    csvFileIcon: {
        fontSize: "12px",
    },
    capitalize: {
        textTransform: "capitalize"
    },
    usersTable: {
        ...tableHeadColumns(theme),
        background: 'transparent',
        "& .rt-thead": {
            position: "sticky",
            zIndex: "1",
            top: "0",
            "& .rt-th": {
                color: infoColor[0]+" !important",
                textAlign: "left !important",
            },
        },
        "& .rt-table": {
            "&:not(:hover)": {
                "&::-webkit-scrollbar": {
                    width: "15px",
                },
                "&::-webkit-scrollbar-track": {
                    backgroundColor: "rgba(0,0,0,0.3)",
                },
                "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "rgba(0,0,0,0.1)",
                    
                }
            }
        },
        "& .rt-tbody .rt-td, & .rt-tbody .rt-td a": {
            fontSize: "12px",
            color: grayColor[7],
        },
        "& .rt-tbody .rt-td": {
            padding: "0px 5px",
            margin: "5px 0px",
            display: "flex",
            alignItems: "center",
            "&.td_name": {
                margin: "0px",
                padding: "0px",
                "& .react-contextmenu-wrapper": {
                    padding: "5px 5px",
                    margin: "0px",
                }
            }
        },
        "& .rt-thead .rt-th.-cursor-pointer > div:first-of-type:after": {
            color: grayColor[17],
            opacity: "1 !important"
        },
        "& .rt-td": {
            fontSize: "12px"
        },
        "& .rt-tr": {
            cursor: "pointer"
        },
        "& .rt-tbody": {
            overflow: "unset",
            "& .rt-tr-group": {
                flexGrow: "0",
            },
            minHeight: "auto !important",
            marginBottom: "10px",
            "& .rt-td": {
                textAlign: "left !important",
            },
            "& .td_action p": {
                textOverflow: "ellipsis",
                width: "100%",
                overflow: "hidden",
                whiteSpace: 'nowrap',
                display: 'block !important',
                fontSize: '12px',
                marginBottom: '0px',
            },
            "& .td_name p": {
                textOverflow: "ellipsis",
                width: "100%",
                overflow: "hidden",
                whiteSpace: 'nowrap',
                display: 'block !important',
                fontSize: '12px',
                marginBottom: '0px',
            }
        },
        [theme.breakpoints.down("xs")]: {
            padding: "10px"
        },
        "& .hd_date_time": {
            width: '5% !important',
        },
        "& .hd_action": {
            width: "30% !important",
            "& button": {
                width: "auto !important",
            }
        },
        "& .hd_name": {
            width: "10% !Important"
        },
        "& .rt-resizer": {
            display: "none !important"
        },
        "& .rt-resizable-header-content:after": {
            display: "none !important"
        }
    },
    UserRecentActivitySection: {
        paddingLeft: '0px',
        paddingTop: '10px',
        "&>p": {
            color: '#659DFA',
            marginBottom: '0px',
            marginLeft: '5px',
        },
        "& .csv-file-icon": {
            textAlign: "right",
            padding: "10px 0px",
            "& img": {
                maxWidth: "24px"
            }
        }
    },
});
  
export default activitiesModalStyle;
  