import React from 'react'
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import Button from "../CustomButtons/Button.jsx";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Close from "@material-ui/icons/Close";
import LoaderComponent from "../Loader";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import changeExtensionsModalStyle from "../../../assets/jss/user/changeExtensionsModalStyle.jsx";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});
Transition.displayName = "Transition";

const ChangeExtensionsModal = class extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            extensions: {
                ms_office: false,
                google_workspace: false,
                docusign: false,
                autodesk: false
            }
        };
    }
    handleCheckbox(e, name){
        this.setState({
            extensions: {
                ...this.state.extensions, 
                [name]: e.target.checked ? 1 : 0
            }
        });
    }
    render() {
        const { classes, saving, confirmationMessage } = this.props;
        const { extensions } = this.state;
        return (
            <Dialog
                classes={{
                    root: classes.modalRoot,
                    paper: classes.modal
                }}
                open={this.props.open}
                TransitionComponent={Transition}
                keepMounted
                aria-labelledby="confirmationModal-modal-slide-title"
                aria-describedby="confirmationModal-modal-slide-description"
            >
                <DialogTitle
                    id="confirmationModal-modal-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                    >
                        <Button
                            simple
                            className={classes.modalCloseButton+" "+classes.modalCloseButtonCustom}
                            key="close"
                            aria-label="Close"
                            onClick={() => this.props.onClose()}
                        >
                            {" "}
                            <Close className={classes.modalClose} />
                        </Button>
                        {
                            saving ?
                                <></>
                            :
                            <h4 className={classes.modalTitle+" "+classes.textCenter}>{confirmationMessage}</h4> 
                        }
                </DialogTitle>
                <DialogContent
                    id="confirmationModal-modal-slide-description"
                    className={classes.modalBody}
                    >
                        {
                            saving ?
                                <LoaderComponent color="custom" align="center" />
                            :
                            <>  
                                <div className={classes.alignLeft}>
                                    <FormControlLabel
                                        classes={{label: classes.label}}
                                        control={
                                            <Switch
                                                checked={(extensions.ms_office ? true : false)}
                                                onChange={(e) => this.handleCheckbox(e, 'ms_office')}
                                                value="ms_office"
                                                classes={{
                                                    switchBase: classes.switchBase,
                                                    checked: classes.switchChecked,
                                                    thumb: classes.switchIcon,
                                                    track: classes.switchBar
                                                }}
                                            />
                                        }
                                        label={"MS Office"}
                                    />
                                </div>
                                <div className={classes.alignLeft}>
                                    <FormControlLabel
                                        classes={{label: classes.label}}
                                        control={
                                            <Switch
                                                checked={(extensions.google_workspace ? true : false)}
                                                onChange={(e) => this.handleCheckbox(e, 'google_workspace')}
                                                value="google_workspace"
                                                classes={{
                                                    switchBase: classes.switchBase,
                                                    checked: classes.switchChecked,
                                                    thumb: classes.switchIcon,
                                                    track: classes.switchBar
                                                }}
                                            />
                                        }
                                        label={"Google Workspace"}
                                    />
                                </div>
                                <div className={classes.alignLeft}>
                                    <FormControlLabel
                                        classes={{label: classes.label}}
                                        control={
                                            <Switch
                                                checked={(extensions.docusign ? true : false)}
                                                onChange={(e) => this.handleCheckbox(e, 'docusign')}
                                                value="docusign"
                                                classes={{
                                                    switchBase: classes.switchBase,
                                                    checked: classes.switchChecked,
                                                    thumb: classes.switchIcon,
                                                    track: classes.switchBar
                                                }}
                                            />
                                        }
                                        label={"Docusign"}
                                    />
                                </div>
                                <div className={classes.alignLeft}>
                                    <FormControlLabel
                                        classes={{label: classes.label}}
                                        control={
                                            <Switch
                                                checked={(extensions.autodesk ? true : false)}
                                                onChange={(e) => this.handleCheckbox(e, 'autodesk')}
                                                value="autodesk"
                                                classes={{
                                                    switchBase: classes.switchBase,
                                                    checked: classes.switchChecked,
                                                    thumb: classes.switchIcon,
                                                    track: classes.switchBar
                                                }}
                                            />
                                        }
                                        label={"Autodesk"}
                                    />
                                </div>
                            </>
                        }
                </DialogContent>
                {
                    saving ?
                        <></>
                    :
                    <DialogActions className={classes.modalFooter +" " +classes.justifyContentCenter}>
                        <Button color="custom" className={classes.changeButton} onClick={() => this.props.onSuccess(extensions)}>
                            Change
                        </Button>
                    </DialogActions>
                }
            </Dialog>
        )
    }
}

ChangeExtensionsModal.defaultProps = {
    open: false,
    confirmationMessage: "",
    saving: false
}
ChangeExtensionsModal.propTypes = {
    onClose: PropTypes.func,
    open: PropTypes.bool,
    confirmationMessage: PropTypes.string,
    saving: PropTypes.bool
}
export default withStyles(changeExtensionsModalStyle)(ChangeExtensionsModal);