import React from 'react'
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import Button from "../../CustomButtons/Button.jsx";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import CustomSelect from "../../CustomInput/CustomSelect";
import Close from "@material-ui/icons/Close";
import axios from "axios";
import Api from "../../../../assets/js/utils/Api";
import ApiSharepoint from "../../../../assets/js/utils/Sharepoint/Api";
import ReactTable from "react-table";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import foldericon from "../../../../assets/img/foldericon.png";
import ChevronRight from "@material-ui/icons/ChevronRight";
// import lastDayOfDecade from 'date-fns/fp/lastDayOfDecade';
import CreateNewFolder from "@material-ui/icons/CreateNewFolder"
import NewFolderModal from "../NewFolderModal.js";

// import SharepointAccountModalStyle from '../../../../assets/jss/user/oneDrive/newEditAccounModalStyle.jsx';
import userEditPageStyle from '../../../../assets/jss/user/userEditPageStyle.jsx';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});


const SharepointAccountModal = class extends React.Component {
    constructor(props){
        super(props);
        this.store = this.props.store;
        let site = localStorage.getItem("sharepoint_site");
        if(!site){
            site = "";
        }
        this.state = {
            emailid: this.props.emailid,
            limit: 200,
            totalItemCount: 0,
            response: [],
            cancelToken: null,
            page: 1,
            loading: false,
            loadingMore: false,
            totalPages: 1,
            currentPath: 'My Docs',
            backPath: "/My Docs/",
            favourites: [],
            file: null,
            creatingFolder: false,
            files: [],
            newDataPush: [],
            checked: '',
            storeOneDriveFolderPath:'',
            currentPathOld: '',
            checkedVal: 'mydoc',
            responseNew: [],
            folderParentIdOneDrive: null,
            folderParentPathOneDrive: null,
            sites: null,
            site: site,
            selectSite: (site.length === 0),
            errorMessage: null,
            newFolderModal: false
        };

        this.handleChange = this.handleChange.bind(this);
    }
    componentDidMount() {
        this.getSites();
        const sharepointSite = localStorage.getItem("sharepoint_site");
        if(sharepointSite){
            this.getDriveData();
        }
        
        const userData = JSON.parse(localStorage.getItem("sendlinx_userinfo"));
        const New = userData.extensions.sharepoint.path;
        if (New) {
            const Newt = this.removeLastCharacterFromUrl(New);
            let getPathW = '';
            if (Newt) {
                getPathW ="/My Docs/"+ Newt;   
            }else{
                getPathW ="/My Docs"; 
            }
            this.myDocTable(null, getPathW, "", false)
        }else{
            const {currentPath} = this.state;
            this.myDocTable(null, currentPath,"",false)
        }
    }
    getSites(){
        const requestData = {
            search: ''
        };
        const email = localStorage.getItem("sharepoint_email");
        const message = "No sharepoint site(s) accessible with "+email+". Please check your permissions to the site(s), or contact your Office 365 administrator.";
        let errorMessage = this.state.errorMessage;
        ApiSharepoint.getSites(requestData).then(data => {
            if(data.value.length <= 0){
                errorMessage = message;
            }
            this.setState({
                loading: false,
                sites: data.value,
                errorMessage: errorMessage
            });
            this.getDriveData();
        }).catch(err => {
            errorMessage = message;
            this.setState({
                loading: false,
                errorMessage: errorMessage
            });
            console.log(err);
        });

        this.setState({
            loading: true
        });
    }
    handleChange(e, name) {
        const value = e.target.value;
        this.setState({
            [name]: value
        });

        if(name === 'site'){
            const oldSite = localStorage.getItem("sharepoint_site");
            if(oldSite !== null){
                localStorage.setItem("sharepoint_site", value);
                this.getDriveData();
            }
        }
    }
    // One Drive API 
    getDriveData(){
        const sharepointSite = localStorage.getItem("sharepoint_site");
        if(!sharepointSite){
            return;
        }
        ApiSharepoint.getDriveFiles().then( async dataRe =>  {
            const getFolderPath = (fullPath) => {
                const path = fullPath;
                const dataAfterRoot = path.split("root:")[1];
                const returnFullPath = "/MyDocs/OneDrive"+dataAfterRoot;
                return returnFullPath;
            };
            const getFolderPathOneDrive = (fullPath) => {
                const path = fullPath;
                const dataAfterRoot = path.split("root:")[1];
                return dataAfterRoot;
            };

            const data = dataRe.value;
            const getFileExtension = (filename) => {
                const parts = filename.split('.');
                return parts[parts.length - 1];
            };

            const newData =[];
            for (let index = 0; index < data.length; index++) {
                const file = data[index];
                const downloadUrl = file['@microsoft.graph.downloadUrl'];
                newData.push({                      
                    "id":data[index].id,
                    "parent_id":data[index].parentReference.driveId,
                    "parent_id_drive":data[index].parentReference.id,
                    "type":data[index]?.folder ? 'folder' : 'file',
                    "name":data[index].name,
                    "orignalSize":data[index].size,
                    "date":data[index].createdDateTime,
                    "modified_date":data[index].lastModifiedDateTime,
                    "downloadLink":downloadUrl,
                    "transfer_type":"sharepoint",
                    "pathOneDrive":getFolderPathOneDrive(data[index]?.parentReference?.path),
                    "path":getFolderPath(data[index]?.parentReference?.path)+"/"+data[index].name,
                    "folder_path":"/MyDocs/OneDrive",
                    "mime_major":data[index]?.file?.mimeType,
                    "extension":getFileExtension(data[index].name),
                    "created_at":data[index].createdDateTime,
                    "updated_at":data[index].lastModifiedDateTime,
                    "folder_id":data[index]?.parentReference?.driveId,
                })
            }
            this.setState({
                responseNew: newData,
                loading: false,
            });
        

            this.loadingFolder = false;
        }).catch(err => {
        if ('add Account' === err) {
            alert('Please Add Account First!!')
            this.setState({
                loading: false,
            });
        }
            console.log(err);
        });
    }
    getNewFolderOrFile(type){
        const { responseNew } = this.state;
        const uniqueFilesFolder = responseNew.filter(value => value.type === type)
        return uniqueFilesFolder;
    }
    moveData(flag){
        const getAllNewFolder = this.getNewFolderOrFile('folder');
        if(getAllNewFolder.length>0){
            this.inserFolderOneDrive(flag,getAllNewFolder);
        }
        const getAllNewFile = this.getNewFolderOrFile('file');
        if(getAllNewFile.length>0){
            this.moveFileOneDrive(flag,getAllNewFile);
        }
    }

    moveFileOneDrive(flag, file, isSync = null, destinationPath = null){
        let requestData = [];
        if (file.length <= 0) {
            return false;
        }
        const source = axios.CancelToken.source();
        const {storeOneDriveFolderPath} = this.state;
        var storePath = ""
        if(storeOneDriveFolderPath === ''){
            storePath = ''
        }else{
            storePath = '' 
        }

        if(isSync){
            for (let index = 0; index < file.length; index++) {
                requestData.push({
                    'url':file[index].url,
                    'file_name':file[index].file_name,
                    'file_size':file[index].file_size,
                    'file_mime':file[index].file_mime,
                    'created_at' : file[index].created_at,
                    'updated_at' : file[index].updated_at,
                    'flag' : flag,
                    'driveId' : file[index].driveId ,
                    'itemId' : file[index].itemId,
                    'flagStatus':file[index].flagStatus,
                    'extension':file[index].extension,
                    "statusExtion":true
                })
            }
        }else{
            for (let index = 0; index < file.length; index++) {
                requestData.push({
                    'url':file[index].downloadLink,
                    'file_name':file[index].name,
                    'file_size':file[index].orignalSize,
                    'file_mime':file[index].mime_major,
                    'created_at' : file[index].date,
                    'updated_at' : file[index].modified_date,
                    'flag' : flag,
                    'driveId' : file[index].parent_id ,
                    'itemId' : file[index].id,
                    'flagStatus':true,
                    'extension':file[index].extension,
                    "filePrentPath":storePath,
                
                })
            }
        }
        ApiSharepoint.moveFileOneDriveToLocal(requestData, source).then(data => {
            this.setState({
                loading: false,
            });
            alert('file copied successfully.');
            
        }).catch(err => {
            console.log(err);
        });
    }

    inserFolderOneDrive(flag = null, data){
        let flageS = false;
        if (flag === "SyncToMyDocs") {
        flageS = true;
        }
        const source = axios.CancelToken.source();
        for (let index = 0; index < data.length; index++) {
            const requestData={
                "folderName":data[index].name,
                "folderUrl":'',
                "folderParentId":4,
                "flagStatus":flageS,
                "itemId":data[index].id,
                "driveId":data[index].parent_id,
                "created_at":data[index].created_at,
                "updated_at":data[index].updated_at
                }
                this.setState({
                    loading: true,
                    folderParentIdOneDrive:data[index].id,
                    folderParentPathOneDrive:data[index].name
                });
                Api.insertFolerEntryToLocal(requestData, source).then(data1 => {
                    this.getAllDataFolderOneDrive(data[index].parent_id, data[index].id, data[index].name, data1.parent_path);
                }).catch(err => {
                    console.log(err);
                });
        }
    }
    async getAllDataFolderOneDrive(pId, folderId, pName, pPath, destinationPath = null){
        const { folderParentIdOneDrive, /*folderParentPathOneDrive*/ } =this.state;
        this.setState({
            loading: true,
        });
        const getFolderPathOneDrive = (fullPath) => {
            const path = fullPath;
            const dataAfterRoot = path.split("root:")[1];
            return dataAfterRoot;
        };
        const getFileExtension = (filename) => {
            const parts = filename.split('.');
            return parts[parts.length - 1];
        };
            const response = await ApiSharepoint.getDataAllFolderOneDrive(pId, folderId).then(data => {
                return data;
            }).catch(err => {
                console.log(err);
            });
            // const { processedFileIds } = this.state;
            const folderData = response.value;
            const source = axios.CancelToken.source();
            if(folderData.length>0){
                const newDataPush1 = [];
                for (let index = 0; index < folderData.length; index++) {
                    if (!folderData[index]?.folder) {
                    // const fileId = folderData[index]?.id;
                        newDataPush1.push({
                            'url': folderData[index]['@microsoft.graph.downloadUrl'],
                            'file_name':folderData[index]?.name,
                            'file_size':folderData[index]?.size,
                            'file_mime':folderData[index]?.file?.mimeType,
                            'parentId':pId,
                            'filePrentPath':decodeURIComponent(this.getActualPath(getFolderPathOneDrive(folderData[index]?.parentReference?.path),pPath)),
                            'created_at' : folderData[index]?.createdDateTime,
                            'updated_at' : folderData[index]?.lastModifiedDateTime,
                            'flag' : "flag",
                            'driveId' : folderData[index]?.parentReference?.driveId ,
                            'itemId' : folderData[index]?.id,
                            'flagStatus':true,
                            'extension':getFileExtension(folderData[index]?.name),
                            "folderParentIdOneDrive":folderParentIdOneDrive,
                            "parent_path":pName,
                            // "destinationPath":destinationPath,
                            "statusExtion":true
                        });
                    }
                }  
                const childFolders = folderData.filter((item) => item.folder);
                const childDataPromises = childFolders.map((folder) => {
                    return folder;
                });
                if (newDataPush1.length > 0 ) {
                    ApiSharepoint.moveFileOneDriveToLocal(newDataPush1, source).then(data => {
                    }).catch(err => {
                        console.log(err);
                    });
                }
                if (childDataPromises.length > 0) {
                    for (let j = 0; j < childDataPromises.length; j++) {
                        const requestData ={
                            "folderName":childDataPromises[j].name,
                            "folderUrl":decodeURIComponent(this.getActualPath(getFolderPathOneDrive(childDataPromises[j]?.parentReference?.path),pPath)),
                            "folderParentId":pId,
                            "flagStatus":false,
                            "itemId":childDataPromises[j].id,
                            "driveId":childDataPromises[j]?.parentReference?.driveId,
                            "created_at":childDataPromises[j].createdDateTime,
                            "updated_at":childDataPromises[j].lastModifiedDateTime,
                            "folderParentIdOneDrive":folderParentIdOneDrive,
                            "parent_path":pName
                        }
                        Api.insertFolerEntryToLocal(requestData, source).then(data => {
                            this.getAllDataFolderOneDrive(childDataPromises[j].parentReference.driveId, childDataPromises[j].id, childDataPromises[j].name, data.parent_path);
                        }).catch(err => {
                                console.log(err);
                        });
                        }
                }
            }
    }
    getActualPath(pathNew,nameNew){
        pathNew = decodeURIComponent(pathNew);
        nameNew = decodeURIComponent(nameNew);

        const position = pathNew.indexOf(nameNew);
        let orPath;
        if (position !== -1) {
            orPath = "/"+pathNew.substring(position);
        } else {
            orPath = pathNew;
        }
    
        return orPath;
    }
    // End One Drive API
    removeLastCharacterFromUrl(url) {

        const lastSlashIndex = url.lastIndexOf('/');
        const segments = url.split('/');
        if (segments.length > 1) {
            return url.slice(0, lastSlashIndex);
        }else{
            return null; 
        }
      }
    extractLastSegmentFromUrl(url) {
        if (!url) {
            return false;
        }
        const segments = url.split('/');
        if (segments.length > 1) {
            return segments[segments.length - 1];
        }else{
            return url;
        }
      }
      
    myDocTable(file =null, newPath = null, backPath = "" ,isBack = false){
        const userData = JSON.parse(localStorage.getItem("sendlinx_userinfo"));
        const New = userData.extensions.sharepoint.path;

        let stateObj = {
            checked:'',
            storeOneDriveFolderPath:'',
            loading: true,
            newFolderModal: false,
            creatingFolder: false
        };
        if (New) {
            stateObj['checkedVal'] = '';
        }

        this.setState(stateObj);

        if (isBack) {
            if (newPath === "/My Docs" || newPath === "" || newPath === "/" ||newPath === "/My Docs/") {
                newPath = null
            }else{
                var lastIndex = newPath.lastIndexOf('/');
                var updatedPath = newPath.substring(0, lastIndex);
                var lastIndex1 = updatedPath.lastIndexOf('/');
                var updatedPath1 = updatedPath.substring(0, lastIndex1);
                newPath = updatedPath1;
            }
        }
        const source = axios.CancelToken.source();
        let requestData = {
            page: 1,
            limit: 200,
            path: newPath == null ? '/My Docs':newPath,
            nested_files: 1
        };
        Api.getFiles(requestData, source).then(data => {
            const userData = JSON.parse(localStorage.getItem("sendlinx_userinfo"));
            const New = userData.extensions.sharepoint.path;
            const Raj = this.extractLastSegmentFromUrl(New);
            if (Raj) {
                data.response.forEach(element => {
                    if(element.name === Raj){
                        this.setState({
                            checked:element.id,
                        })
                    }
                });
            }
           this.setState({
            response: data.response,
            currentPath:(data.response.length !== 0 ? data.response[0].folder_path : newPath)
           })
         
        }).catch(err => {
            this.setState({ loading: false })
        });
    }
    getBackPath(path){
        if(typeof(path) !== "string" || path.trim().length <= 0){
            return "";
        }
        if(path.length <= 1 || path.trim() === "/"){
            return "";
        }
        if(path.substr(0, 1) !== "/"){
            path += "/";
        }
        path = decodeURIComponent(path.trim()); //Decode path
        path = path.replace(/^\/+|\/+$/g, ''); //Remove trailing slash
        const pathArray = path.split("/");
        pathArray.pop();
        let backPath = pathArray.join("/");
        if(backPath.length <= 0){
            backPath = "/";
        }
        return backPath;
    }  
    handleChackBox(file){
     const { checked} = this.state;
        if(checked !=='' && checked === file.id){
            this.setState({
                checked:'',
                storeOneDriveFolderPath:''
             })
        }
        else{
            this.setState({
                checked:file.id,
                checkedVal:'',
                storeOneDriveFolderPath:file.path
            })
      }
    }
    handleChackBoxRoot(file){
        const {checkedVal } = this.state;
        if (checkedVal === "mydoc") {
            this.setState({
                checkedVal:'',
                storeOneDriveFolderPath:''
            })
        }else{
            this.setState({
                checkedVal:'mydoc',
                storeOneDriveFolderPath:''
            })
        }
       
    }
    updateSync(){
        const source = axios.CancelToken.source();
        const {user} = this.props
       
        let requestData = {
            userId: user.id,
            driveName: "sharepoint"
        };
        ApiSharepoint.updateSyncStatus(requestData, source).then(data => {
        }).catch(err => {
           console.log('something went wrong',err);
        }); 
    }
    saveOneDrivePath(){
        this.setState({
            loading: true
        })
        const source = axios.CancelToken.source();
        const {user} = this.props
        const { storeOneDriveFolderPath, /* responseNew */ } = this.state;
        // const leftMemorySize = (user.quota - user.usage);
        // const totalSize = responseNew.reduce((accumulator, obj) => accumulator + obj.orignalSize, 0);
        // const overAllSize = totalSize + 0;
        // if (leftMemorySize < overAllSize ) {
        //     alert('Not enough storage space in My Docs');
        //      return;
        // }
        let requestData = {
            path: storeOneDriveFolderPath.slice(9),
            name: "sharepoint",
            userId: user.id
        };
        this.setState({
            loading: true
        })
        Api.addOneDrivePath(requestData, source).then(data => {
            const userData = JSON.parse(localStorage.getItem("sendlinx_userinfo"));
            userData.extensions.sharepoint.path = data?.data?.path;
            localStorage.setItem("sendlinx_userinfo", JSON.stringify(userData));
            // const neL =localStorage.getItem("sharepoint_status");
            // localStorage.setItem("sharepoint_connection", neL);
            // this.updateSync();
            // if (neL) {
            //     this.moveData(neL);
            // }else{
            //     this.moveData('CopyToMyDocs');
            // }
            this.setState({
                loading: false
            })
            this.props.onClose(false, true);
        }).catch(err => {
            this.setState({ loading: false })
        }); 
    }
    getColumns(){
        let columns = [
            {
                Header: "",
                accessor: "check",
                sortable: false,
                filterable: false,
                headerClassName: "hd_check",
                className: "hd_check td_check",
            },
            {
                Header: "Name",
                accessor: "name",
                headerClassName: "hd_name",
                className: "hd_name td_name td_hide",
            },
           
        ];

        return columns;
    }
    getTableData(){
        const { classes } = this.props;
        const { response,backPath ,currentPath,checked,checkedVal} = this.state;
        if(response === null){
            return [];
        }
        let tableData = [];
            let fileArray = {
                id: 0,
                orignalName: "",
                check:"",
                name: (<Link href="/user/home"  onClick={(e) => {e.preventDefault(); this.myDocTable('',currentPath, backPath,true);}}>
                        <Button justIcon color="transparent" className={classes.fileIcon}>
                            <img src={foldericon} alt={"folder"} />
                        </Button>
                        {"..."}
                    </Link>
                ),
                orignalSize: "",
                size: "",
                path:'',
                folderPath: "",
                recipients: "",
                type:''
            };
            tableData.push(fileArray);
        response.map((file) => {
           
            if(file.type==='folder'){
                let fileArray = {
                    id: file.id,
                    check:(
                        <Checkbox
                        className={classes.positionAbsolute}
                        disabled={( checked !=='' && file.id !== checked)}
                        classes={{
                             checked: classes.checked,
                            root: classes.checkRoot
                        }}
                        checked={!checkedVal ? (file.id === checked ? true : false) :false}
                        onClick={() => this.handleChackBox(file)}
                    />
                    ),
                    type: file.type,
                    orignalName: file.name,
                    name: (
                        <div style={{display:'flex'}}>
                        <div className='thumbnail-icon'>
                            <Button justIcon color="transparent" className={classes.fileIcon}>
                            <img src={foldericon} alt={"folder"} />
                            </Button>
                        </div>
                        <Link href={"/user/home"}  onClick={(e) => {e.preventDefault(); this.myDocTable(file, file?.path, backPath,false);}}>
                            <div className='filename'>{file.name}</div>
                        </Link>
                    </div>
                    ),
                    size: file.size,
                    folderPath: file?.folder_path,
                    path: file?.folder_path,
                };
                tableData.push(fileArray);
            }
            
            return null;
            
        });
            
        return tableData;
    }
    getBreadCrumbs(){
        let path = '';
        const { currentPath} = this.state;
        path = currentPath;
        let breadcrumbs = [];
        if(path === "/"){
            return breadcrumbs;
        }
        if(path.substr(0,1) === "/"){
            path = path.substr(1);
        }
        const pathArray = path.split("/");
        const newdar = pathArray.filter(function (item){
            return item !== "";
        } );
        let newPath = "";
        newdar.map(path => {
            newPath += "/"+path;
            breadcrumbs.push({
                value: newPath,
                label: path
            });
            return null;
        });
        return breadcrumbs;
    }
    onNewFileModalSuccess(folderName){
        const {currentPath} = this.state;
        const source = axios.CancelToken.source();
        this.setState({
            creatingFolder: true,
            cancelToken: source,
        });
        const requestData = {
            folderName: folderName,
            path: currentPath
        }
        Api.addNewFolder(requestData, source).then(data => {
          const newPath= currentPath;
          this.myDocTable(null, newPath,"" ,false)
        }).catch(err => {
            console.log(err);
        });
    }
    getSiteOptions(){
        const { sites } = this.state;
        if(!sites){
            return [];
        }
        let sitesArray = [];
        sites.map((site) => {
            if(site.name === "Community" || site.name === "hub"){
                return null;
            }
            sitesArray.push({
                key: site.id,
                value: site.displayName
            });
            return null;
        });

        if(sitesArray.length > 0){
            sitesArray = sitesArray.sort((a, b) => a.value.localeCompare(b.value));
        }
        return sitesArray;
    }
    onSiteSelect(site){
        this.setState({
            selectSite: false,
        });

        localStorage.setItem("sharepoint_site", site);
    }
    renderSiteSelect(){
        const { classes } = this.props;
        return (
            <CustomSelect 
                formControlProps={{
                    fullWidth: true,
                    className: classes.customSelect
                }}
                labelText="Sharepoint Site"
                selectProps={{
                    onChange: (e) => this.handleChange(e,"site"),
                    value: this.state.site,
                }}
                inputProps={{
                    name: "site",
                    id: "input-site",
                    className: classes.alignLeft,
                }}
                items={this.getSiteOptions()}
                itemLabel="value"
                itemValue="key"
                color="custom"
            />
        )
    }
    onNewFolderModal(status = false){
        this.setState({
            newFolderModal: status
        })
    }
    render() {
        const { classes } = this.props;
        const {checked, checkedVal, selectSite, site, errorMessage, newFolderModal, creatingFolder } = this.state;
        const {currentPath}=this.state;
        const tableData = this.getTableData(); 
        const breadcrumbs = this.getBreadCrumbs(currentPath);
        const userData = JSON.parse(localStorage.getItem("sendlinx_userinfo"));
        const New = userData.extensions.sharepoint.path;
        return (
            <Dialog
                classes={{
                    root: classes.modalRoot,
                    paper: classes.modal
                }}
                open={this.props.open}
                TransitionComponent={Transition}
                keepMounted
                aria-labelledby="SharepointAccountModal-modal-slide-title"
                aria-describedby="SharepointAccountModal-modal-slide-description"
            >
                <DialogTitle
                    style={{ padding: "16px 24px 0px 24px"}}
                    id="SharepointAccountModal-modal-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                >
                    <Button
                        style={{color:"#999"}}
                        simple
                        className={classes.modalCloseButton+" "+classes.modalCloseButtonCustom}
                        key="close"
                        aria-label="Close"
                        onClick={() => this.props.onClose()}
                    >
                        <Close className={classes.modalClose} />
                    </Button>
                    <h4 className={classes.modalTitle+" "+classes.textCenter}>
                        {
                            selectSite ?
                                "Site Configuration"
                            :
                                "Confirm Default Destination Folder"
                        }
                    </h4>
                </DialogTitle>
                <DialogContent
                    id="SharepointAccountModal-modal-slide-description"
                    className={classes.modalBody}
                >
                    {
                        errorMessage !== null ?
                            <p className={classes.errorMessage}>{errorMessage}</p>
                        : selectSite ?
                            <>
                                {this.renderSiteSelect()}
                                <DialogActions style={{marginTop:"-10px"}} className={classes.justifyContentCenter}>
                                    <Button  color="custom" className={classes.changeButton} disabled={(site.length === 0)} onClick={() => this.onSiteSelect(site)}>
                                        Continue
                                    </Button>
                                </DialogActions>
                            </>
                        :
                            <>
                                {this.renderSiteSelect()}
                                <div className='defultPath'>
                                    <p style={{textAlign:"left",width:"80%",float:"left", margin:"0px"}}>Current Destination Directory: /My Docs</p>
                                    <Checkbox
                                        className={classes.positionAbsolute}
                                        style={{float:"right", padding:"0px"}}
                                        // disabled={( checked !=='' &&' file.id' !== checked)}
                                        classes={{
                                            checked: classes.checked,
                                            root: classes.checkRoot
                                        }}
                                        checked={checkedVal === "mydoc" ? true : false}
                                        onClick={() => this.handleChackBoxRoot(1)}
                                    />
                                </div>
                                <p style={{textAlign:"left", margin:"0px", clear: "both"}}>Destination Path : /My Docs/{New}</p>
                                <div className={classes.sdBackNewfolder}>
                                    <div className={classes.breadcrumbs} style={{textAlign:"left",padding:"5px"}}>
                                        <div>
                                            <img src={foldericon} alt={"folder"} />
                                            {
                                                breadcrumbs.map((breadCrumb, key) => {
                                                    return (
                                                        <div key={key}>
                                                            {
                                                                key !== 0 ?
                                                                    <span><ChevronRight /></span>
                                                                :
                                                                <></>
                                                            }
                                                            <Link href="/user/home" onClick={(e) => {e.preventDefault();this.myDocTable('',breadCrumb.value, '',false);}}>
                                                                {breadCrumb.label}
                                                            </Link>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                    <Button color="transparent" onClick={() => this.onNewFolderModal(true)} justIcon round><CreateNewFolder /></Button>
                                </div>
                                <ReactTable style={{overflow:"auto", height:"230px"}}
                                    data={tableData}  
                                    columns={this.getColumns()}
                                    defaultPageSize={100} 
                                    showPaginationTop={false}
                                    minRows={0}
                                    showPaginationBottom={false}
                                    className={"-striped -highlight  "+classes.filesTable+" "} 
                                />
                            </>
                    }
                </DialogContent>
                {
                    selectSite === false ?
                        <DialogActions className={classes.modalFooter +" " +classes.justifyContentCenter}>
                            <Button  color="custom" className={classes.changeButton} onClick={() => this.saveOneDrivePath()} disabled={(checked === '' && checkedVal ==="")}>
                                {
                                    checked === '' && checkedVal ==="" ?
                                        <>Continue</>
                                    :
                                        <>Update</>
                                }
                            </Button>
                        </DialogActions>
                    :
                    null
                }
                {
                    newFolderModal ?
                        <NewFolderModal
                            open={newFolderModal}
                            saving={creatingFolder}
                            store={this.store} 
                            onClose={() => this.onNewFolderModal(false)}
                            onSuccess={(folderName) => this.onNewFileModalSuccess(folderName)}
                            confirmationMessage="Create new folder"
                        />
                    :
                        <></>
                }
            </Dialog>
        )
    }
}

SharepointAccountModal.defaultProps = {
    open: false,
    saving: false,
    emailid: ""
}
SharepointAccountModal.propTypes = {
    onClose: PropTypes.func,
    open: PropTypes.bool,
    saving: PropTypes.bool
}
export default withStyles(userEditPageStyle)(SharepointAccountModal);