import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import Button from "../../components/CustomButtons/Button.jsx";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import CustomInput from "../CustomInput/CustomInput.jsx";
import deleteFileModalStyle from "../../../assets/jss/user/deleteFileModalStyle";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
Transition.displayName = "Transition";

const DeleteExternalLinkModal = class extends React.Component {
    constructor(props){
        super(props);
        this.store = this.props.store;

        this.state = {
            deleteText: ''
        }
    }
    handleChange(e, name){
        let state = {};
        state[name] = e.target.value;
        this.setState(state);
    }
    render() {
        const { classes, deleteLinks, confirm } = this.props;
        const { deleteText } = this.state;
        return (
            <Dialog
                classes={{
                    root: classes.modalRoot,
                    paper: classes.modal
                }}
                open={this.props.open}
                TransitionComponent={Transition}
                keepMounted
                aria-labelledby="deleteExternalLink-slide-title"
                aria-describedby="deleteExternalLink-slide-description"
            >
                <DialogTitle
                    id="deleteExternalLink-modal-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                    >
                        <h4 className={classes.modalTitle+" "+classes.textCenter}>Are you sure you want to delete?</h4> 
                </DialogTitle>
                <DialogContent
                id="deleteExternalLink-slide-description"
                className={classes.modalBody}
                >
                    {
                        confirm && deleteLinks.length > 5 ?
                            <CustomInput
                                id="input-deleteText"
                                labelText="Type delete for confirm"
                                inputProps={{
                                    onChange: (e) => this.handleChange(e,'deleteText'),
                                    value: deleteText,
                                    name: "deleteText",
                                    type: "text",
                                }}
                                color="custom"
                                formControlProps={{
                                    fullWidth: true,
                                    className: classes.customFormControlClasses
                                }}
                            />
                        :
                            <></>
                    }
                </DialogContent>
                <DialogActions
                    className={classes.modalFooter + " " + classes.justifyContentCenter}
                >
                    <Button color="custom" disabled={ confirm && deleteLinks.length > 5 && deleteText.toLowerCase() !== 'delete' ? true : false } onClick={() => this.props.onSuccess()} round>Delete</Button>
                    <Button color="white" onClick={() => this.props.onClose()} round>Cancel</Button>
                </DialogActions>        
            </Dialog>
        );
    }
};

DeleteExternalLinkModal.defaultProps = {
  open: false,
  deletingUser: false,
  deleteAll: false,
  confirm: false
};
DeleteExternalLinkModal.propTypes = {
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
  open: PropTypes.bool,
  deleteAll: PropTypes.bool,
  confirm: PropTypes.bool
};
export default withStyles(deleteFileModalStyle)(DeleteExternalLinkModal);
