import {
    whiteColor,
    grayColor,
    blackColor,
    dangerColor,
    successColor,
} from "../material-kit-pro-react.jsx";

import customCheckboxRadioSwitchStyle from "../material-kit-pro-react/customCheckboxRadioSwitchStyle.jsx";
const portalSectionsStyle = theme => ({
    ...customCheckboxRadioSwitchStyle,
    PortalSections: {
        "& .subscriptions-section": {
            background: "#f4f5f9",
            boxShadow: "0 20px 30px 1px rgba(0,0,0,.15)",
            borderRadius: "4px",
        },
        "& .subscription-header": {
            background: whiteColor,
            borderRadius: "4px 4px 0 0",
            boxShadow: "0 1px 2px 0 rgba(0,0,0,.1)",
            textAlign: "center",
            padding: "12px 36px",
            position: "relative",
            minHeight: "64px",
            "& .subscription-title": {
                margin: "0px",
                fontSize: "16px",
                lineHeight: "40px",
                color: theme.customize.headingsColor,
                fontWeight: "bold",
            }
        },
        "& .subscription-body-content": {
            padding: "12px 36px",
            "& .subscription-tabs": {
                marginTop: "12px",
                marginBottom: "12px",
            },
            "& .subscription-tab": {
                boxShadow: "0 2px 7px 1px rgba(0,0,0,.05)",
                fontSize: "13px",
                color: blackColor,
                "&:first-child": {
                    borderRadius: "4px 4px 0px 0px",
                },
                "&:last-child": {
                    borderRadius: "0px 0px 4px 4px",
                },
                "&:not(:last-child)": {
                    borderBottom: "1px solid "+grayColor[2],
                }
            },
            "& .subscription-tab-content": {
                backgroundColor: whiteColor,
                cursor: "pointer",
                position: "relative",
                padding: "9px 15px",
                "&:hover": {
                    backgroundColor: "transparent",
                },
                "& .canceled": {
                    fontSize: "10px",
                    padding: "2px",
                    border: "1px solid "+dangerColor[0],
                    color: dangerColor[0],
                    display: "inline-block",
                    verticalAlign: "middle",
                    lineHeight: "13px",
                    marginRight: "5px",
                    borderRadius: "2px",
                    marginTop: "-2px",
                },
                "& .current_plan": {
                    fontSize: "10px",
                    padding: "2px",
                    border: "1px solid "+successColor[0],
                    color: successColor[0],
                    display: "inline-block",
                    verticalAlign: "middle",
                    lineHeight: "13px",
                    marginRight: "5px",
                    borderRadius: "2px",
                    marginTop: "-2px",
                },
                "& >h4": {
                    marginTop: "3px",
                    marginBottom: "3px",
                    fontWeight: "500",
                    fontSize: "14px",
                    display: "inline-block",
                    verticalAlign: "middle",
                },
                "& .tab-bar-content": {
                    maxWidth: "96%",
                    marginTop: "3px",
                    marginBottom: "3px",
                    "& .bar-price": {
                        fontSize: "14px",
                        fontWeight: "500",
                    },
                    "& .tab-bar-desc": {
                        fontSize: "12px",
                        opacity: ".5",
                        lineHeight: "20px",
                    }
                }
            },
            "& .subscription-links": {
                marginTop: "24px",
                marginBottom: "24px",
                "& div": {
                    color: theme.customize.linksColor,
                    marginTop: "16px",
                    marginBottom: "16px",
                    cursor: "pointer",
                    fontSize: "14px",
                    "& .link-icon": {
                        "& svg": {
                            width: "18px",
                            height: "18px",
                            verticalAlign: "middle",
                            marginRight: "6px",
                            marginTop: "-2px",
                        },
                    },
                    "&.exit-div": {
                        color: "#7c7c7c",
                    }
                }
            }
        },
        "& .inner-section": {
            background: "#f4f5f9",
            boxShadow: "0 20px 30px 1px rgba(0,0,0,.15)",
            borderRadius: "4px",
            "& .inner-header": {
                cursor: "pointer",
                background: whiteColor,
                borderRadius: "4px 4px 0 0",
                boxShadow: "0 1px 2px 0 rgba(0,0,0,.1)",
                padding: "13px 36px 11px",
                position: "relative",
                minHeight: "44px",
                "& .inner-title": {
                    margin: "0px",
                    fontSize: "14px",
                    lineHeight: "18px",
                    color: "rgb(33, 33, 40)",
                    "& svg": {
                        verticalAlign: "middle",
                        marginTop: "-2px",
                        width: "18px",
                        height: "18px",
                    }
                },
                "&:hover": {
                    background: "linear-gradient(-180deg,#e7e8ed 38%,#fff)",  
                },
            },
            "& .inner-body-content": {
                padding: "12px 36px",
                "& .address-section": {
                    marginTop: "12px",
                    marginBottom: "12px",
                    "& h4": {
                        fontSize: "13px",
                        lineHeight: "21px",
                        marginBottom: "10px",
                        fontWeight: "500",
                        color: blackColor,
                    },
                    "& .address-bar": {
                        boxShadow: "0 2px 7px 1px rgba(0,0,0,.05)",
                        fontSize: "13px",
                        color: blackColor,
                        cursor: "pointer",
                    },
                    "& .address-content": {
                        background: whiteColor,
                        position: "relative",
                        padding: "9px 15px",
                        borderRadius: "4px",
                        "&:hover": {
                            backgroundColor: "transparent",
                        },
                    }
                },
                "& .shipping-address-section": {
                    marginTop: "24px",
                    marginBottom: "24px",
                }
            }
        },
        "& .payment-methods-section": {
            "& .payment-cards": {  
                marginTop: "12px",
                marginBottom: "12px",
            },
            "& .payment-card": {
                backgroundColor: whiteColor,
                boxShadow: "0 2px 7px 1px rgba(0,0,0,.05)",
                "&:hover": {
                    backgroundColor: "transparent",  
                },
                "&:first-child": {
                    borderRadius: "4px 4px 0px 0px",
                },
                "&:last-child": {
                    borderRadius: "0px 0px 4px 4px",
                },
                "& .payment-card-section": {
                    position: "relative",
                    padding: "9px 15px",
                    cursor: "pointer",
                    "& .payment-card-content": {
                        maxWidth: "96%",
                        marginTop: "3px",
                        marginBottom: "3px",
                        color: blackColor,
                        fontSize: "13px",
                        lineHeight: "17px",
                        "& .card-name": {
                            "& i": {
                                fontSize: "16px",
                                verticalAlign: "middle",
                                marginTop: "-2px",
                                marginRight: "5px",
                                "&:before": {
                                    color: "#0f2899",
                                }
                            }
                        },
                        "& .card-type": {
                            float: "right",
                            backgroundColor: "rgb(33, 150, 243)",
                            minWidth: "56px",
                            minHeight: "16px",
                            color: whiteColor,
                            textAlign: "center",
                            fontSize: "9px",
                            lineHeight: "16px",
                            fontWeight: "500",
                            borderRadius: "2px",
                            padding: "1px 3px",
                            textTransform: "uppercase",
                            "&.backup": {
                                backgroundColor: grayColor[17],
                            }
                        }
                    }
                }
            },
            "& .add-payment-card": {
                textAlign: "center",
                paddingTop: "12px"
            }
        },
        "& .payments-notification": {
            fontWeight: "500",
            fontSize: "12px",
            color: whiteColor,
            padding: "9px 36px",
            margin: "-12px -36px 24px",
            backgroundColor: "rgb(33, 150, 243)",
            display: "flex",
            "& svg": {
                width: "14px",
                height: "14px"
            },
            "& .notification-desc": {
                whiteSpace: "normal",
                lineHeight: "18px",
                paddingLeft: "8px"
            },
            "& .link": {
                textDecoration: "underline",
                cursor: "pointer"
            },
            "&.canceled": {
                backgroundColor: dangerColor[0]
            }
        },
        "& .subscription-detail-section": {
            "& .shipping-card-content": {
                marginTop: "24px",
                marginBottom: "24px",
                "&>div:first-child": {
                    borderRadius: "4px 4px 0px 0px"
                },
                "&>div:last-child": {
                    borderRadius: "0px 0px 4px 4px"
                },
                "&>div+div": {
                    borderTop: "1px solid "+grayColor[2]
                }
            },
            "& .address-section": {
                marginTop: "0px !Important",
                marginBottom: "0px !Important",
                "& h4": {
                    marginBottom: "3px !Important",
                    marginTop: "3px"
                }
            },
            "& .payment-card": {
                "& .payment-card-section": {
                    "& .payment-card-content": {
                        maxWidth: "96%",
                        marginTop: "3px",
                        marginBottom: "3px",
                        color: blackColor,
                        fontSize: "13px",
                        lineHeight: "17px",
                        "& .card-name": {
                            "& i": {
                                fontSize: "16px",
                                verticalAlign: "middle",
                                marginTop: "-2px",
                                marginRight: "5px",
                                "&:before": {
                                    color: "#0f2899",
                                }
                            }
                        },
                    }
                }
            },
            "& .shipping-card-section": {
                backgroundColor: whiteColor,
                "&:hover": {
                    backgroundColor: "transparent",
                },
                "& .address-bar": {
                    boxShadow: "none",
                    "& .address-content": {
                        backgroundColor: "transparent",
                        borderRadius: "0px",
                    }
                }
            },
            "& .subscription-detail": {
                marginTop: "12px",
                marginBottom: "12px",
                color: blackColor,
                "& h4": {
                    fontSize: "14px",
                    fontWeight: "500",
                    lineHeight: "21px",
                    marginTop: "9px",
                    marginBottom: "0px",
                },
                "&>div": {
                    marginTop: "3px",
                    marginBottom: "3px"
                },
                "& .bar-price": {
                    fontSize: "14px",
                    fontWeight: "500",
                },
                "& .tab-bar-desc": {
                    opacity: ".5",
                    fontSize: "12px",
                },
                "& .next_billing_date .tab-bar-desc": {
                    fontSize: "14px"
                }
            },
            "& .subscription-body-content": {
                padding: "0px",
                "& .subscription-links": {
                    marginTop: "12px",
                    marginBottom: "12px",
                    "& div": {
                        marginTop: "10px",
                        marginBottom: "10px",
                    }
                }
            },
        },
        "& .update-form-btn": {
            textAlign: "center",
            paddingTop: "12px"
        },
        "& .payment-detail-content": {
            "& .card-name": {
                textAlign: "center",
                marginTop: "12px",
                marginBottom: "12px",
                "& i": {
                    fontSize: "32px",
                    "&:before": {
                        color: "#0f2899",
                    }
                }
            },
            "& .card-detail": {
                fontSize: "16px",
                textAlign: "center",
                lineHeight: "28px",
                marginTop: "12px",
                marginBottom: "12px",
                color: blackColor,
            },
            "& .card-type": {
                textAlign: "center",
                position: "relative",
                color: "#93939e",
                fontSize: "12px",
                "& svg": {
                    width: "16px",
                    height: "16px",
                    verticalAlign: "middle",
                    marginTop: "-3px",
                    marginLeft: "6px",
                },
                "& div": {
                    color: theme.customize.linksColor,
                    cursor: "pointer"
                }
            },
            "& .subscription-body-content": {
                padding: "0px",
                "& .subscription-links": {
                    margin: "0px"
                }
            },
            "& .card-payment-method": {
                marginTop: "12px",
                marginBottom: "12px",
                "& .title": {
                    fontSize: "16px",
                    fontWeight: "500",
                    lineHeight: "21px",
                    margin: "20px 0 10px",
                    color: blackColor
                },
            },
            "& .card-payment-role": {
                background: whiteColor,
                padding: "9px 15px",
                marginTop: "3px",
                marginBottom: "3px",
                borderRadius: "2px",
                cursor: "pointer",
                boxShadow: "0 2px 7px 1px rgba(0,0,0,.05)",
                "& .card-role-option": {
                    color: blackColor,
                    display: "flex",
                    alignItems: "self-start",
                    "& .role-option-name": {
                        fontSize: "12px",
                        lineHeight: "18px",
                        margin: "0 0 2px",
                    },
                    "& .role-option-desc": {
                        fontSize: "12px",
                        lineHeight: "16px",
                        opacity: "0.5"
                    }
                }
            },
            "& .submit-payment-role": {
                textAlign: "center",
                paddingTop: "12px"
            },
            "& .primary-note": {
                padding: "8px 12px",
                backgroundColor: "#f1f6fb",
                borderRadius: "2px",
                fontSize: "12px",
                display: "flex",
                marginTop: "10px",
            },
            "& .primary-note-icon svg": {
                width: "16px",
                marginRight: "2px",
                color: theme.customize.iconsColor
            },
            "& .bacup-source-actions": {
                "& span:first-child:after": {
                    content: '""',
                    backgroundColor: grayColor[0],
                    verticalAlign: "middle",
                    display: "inline-block",
                    width: "3px",
                    height: "3px",
                    borderRadius: "50%",
                    margin: "0 4px",
                }
            }
        },
        "& .next-arrow": {
            position: "relative",
            "&::after": {
                content: '"\\f105"',
                right: "10px",
                backgroundRepeat: "no-repeat",
                position: "absolute",
                top: "50%",
                transform: "translateY(-50%)",
                margin: "auto",
                fontFamily: '"Font Awesome 5 Free"',
                fontWeight: "900",
                color: blackColor,
            }
        },
        "& .billing-history-invoice": {
            marginTop: "12px",
            marginBottom: "12px",
            "&:not(:last-child)": {
                borderBottom: "1px solid "+grayColor[11],
            },
            "& .billing-history-invoice-content": {
                position: "relative",
                padding: "0 0 12px 9px",
                "&::before": {
                    content: '""',
                    left: "-5px",
                    top: "7px",
                    height: "5px",
                    width: "5px",
                    position: "absolute",
                    background: theme.customize.iconsColor,
                    borderRadius: "50%",
                }
            },
            "& .payment-card-content": {
                display: "flex",
                width: "100%",
                fontSize: "13px",
                color: blackColor,
                lineHeight: "18px",
                justifyContent: "space-between",
                alignItems: "center",
                "& .invoice-title": {
                    fontWeight: "500"
                },
                "& .invoice-price": {
                    fontWeight: "500",
                    marginRight: "8px"
                },
                "& .invoice-download": {
                    "& button":{
                        textDecoration: "none",
                        color: theme.customize.linksColor,
                        padding: "0px",
                        margin: "0px",
                        textTransform: "unset"
                    }
                }
            },
        },
        "& .payment-methods-lists": {
            marginTop: "12px",
            marginBottom: "12px",
            "& .payment-card-content": {
                display: "flex",
                alignItems: "normal",
                margin: "0px !Important",
            },
            "& .payment-card":{
                "& .payment-card-detail": {
                    lineHeight: "22px",
                },
                "& .payment-card-section": {
                    background: whiteColor,
                    padding: "9px 15px",
                    marginTop: "3px",
                    marginBottom: "3px",
                    borderRadius: "2px",
                    outline: "none",
                    cursor: "pointer",
                    boxShadow: "rgba(0, 0, 0, 0.05) 0px 2px 7px 1px",
                }
            }
        },
        "& .update-payment-methods": {
            textAlign: "center",
            paddingTop: "12px"
        },
        "& .cancellation-form-content": {
            color: blackColor,
            textAlign: "center",
            "& .title": {
                fontSize: "24px",
                letterSpacing: "-.51px",
                lineHeight: "28px",
                maxWidth: "270px",
                margin: "9px auto",
            },
            "& .reason-field": {
                marginTop: "24px",
                marginBottom: "24px"
            }
        },
        "& .edit-subscription-section": {
            "& .subscription-detail": {
                color: blackColor,
                marginTop: "12px",
                marginBottom: "12px",
                maxWidth: "285px",
                "& .price": {
                    fontSize: "20px",
                    fontWeight: "700",
                },
                "& .name": {
                    fontWeight: "500",
                    fontSize: "15px",
                },
                "& .desc": {
                    fontSize: "12px",
                    color: grayColor[7],
                    lineHeight: "16px",
                    marginTop: "6px",
                    marginBottom: "6px",
                }
            },
            "& .address-section h4": {
                margin: "0px !Important"
            },
            "& .shipping-card-content": {
                marginTop: "24px",
                marginBottom: "24px",
                "& .card-name": {
                    "& i": {
                        fontSize: "16px",
                        verticalAlign: "middle",
                        marginTop: "-2px",
                        marginRight: "5px",
                        "&:before": {
                            color: "#0f2899",
                        }
                    }
                },
            },
            "& .confirm-btn": {
                paddingTop: "12px",
                textAlign: "center",
            },
            "& .links-content": {
                "&.hide, .hide": {
                    display: "none",
                }
            },
            "& .addons-btn": {
                marginTop: "20px",
                marginBottom: "20px",
            },
            "& .apply-coupon-link": {
                marginTop: "12px",
                marginBottom: "12px",
                "& div": {
                    cursor: "pointer",
                    color: theme.customize.linksColor,
                    fontSize: "12px"
                }
            }
        },
        "& .order-summary-content": {
            marginTop: "12px",
            marginBottom: "12px",
            color: blackColor,
            "& .title": {
                fontSize: "14px",
                lineHeight: "21px",
                fontWeight: "500"
            },
            "& .order-name-price": {
                display: "flex",
                justifyContent: "space-between",
                marginTop: "12px",
                marginBottom: "12px",
                fontSize: "14px",
                opacity: "0.9",
                position: "relative",
                "&:before": {
                    content: '""',
                    left: "-10px",
                    top: "10px",
                    height: "5px",
                    width: "5px",
                    position: "absolute",
                    borderRadius: "50%",
                    backgroundColor: theme.customize.iconsColor,
                }
            },
            "& .order-detail-footer": {
                display: "table",
                marginLeft: "auto",
                minWidth: "240px",
            },
            "& .order-total-amount": {
                borderTop: "1px solid "+grayColor[17],
                marginTop: "12px",
                marginBottom: "12px",
                paddingTop: "10px",
                fontWeight: "500",
                fontSize: "18px",
                marginLeft: "auto",
                textAlign: "right",
            }
        },
        "& .add-addons-options": {
            "& .add-addons-option": {
                padding: "9px 15px 9px 0px",
                display: "flex",
                alignItems: "center",
                color: blackColor,
                "& .addon-option-name": {
                    fontSize: "12px",
                    lineHeight: "18px",
                },
                "& .addon-option-price": {
                    fontSize: "14px",
                    lineHeight: "18px",
                    fontWeight: "bold",
                }
            },
            "& >div+div": {
                borderTop: "1px solid "+grayColor[2]
            }
        },
        "& .addons-btn": {
            textAlign: "center",
            paddingTop: "12px"
        },
        "& .radio-checkbox": {
            position: "relative",
            marginRight: "5px",
            display: "flex",
            "& label": {
                margin: "0px",
                "& .MuiCheckbox-root": {
                    padding: "0px",
                    "& svg": {
                        borderColor: theme.customize.checkboxColor,
                        fill: whiteColor,
                    },
                    "&.Mui-checked": {
                        "& svg": {
                            borderColor: "#2171C6",
                            background: "#2171C6"
                        }
                    }
                },
            },
        },
        "& .radio-label": {
            cursor: "pointer"
        },
        "& .zero-content": {
            textAlign: "center",
            color: blackColor
        },
        "& .error-message-section": {
            paddingBottom: "15px",
        },
        "& .load-more-btn": {
            "& button": {
                width: "100%"
            }
        }
    }
});

export default portalSectionsStyle;
