import modalStyle from "../material-kit-pro-react/modalStyle.jsx";


const renameFileModalStyle = theme => ({
    ...modalStyle(theme),
    textCenter: {
        textAlign: "center",
        marginBottom: "10px"
    },
    justifyContentCenter: {
        WebkitBoxPack: "center !important",
        MsFlexPack: "center !important",
        justifyContent: "center !important",
        alignSelf: "center !important"
    },
    modal: {
        ...modalStyle(theme).modal,
        maxWidth: "400px !important",
        "& .last-modified": {
            marginBottom: "0px",
            top: "20px",
            left: "38px",
            margin: "-1rem auto -1rem 0rem",
            padding: "10px 0px",
            position: "absolute",
            lineHeight: "normal",
            textTransform: "none"
        }
    },
    modalTitle: {
        ...modalStyle(theme).modalTitle,
        textTransform: "capitalize",
        fontWeight: "bold",
        marginTop: "10px"
    },
    modalBody: {
        ...modalStyle(theme).modalBody,
        paddingBottom: "0px",
        paddingTop: "0px",
        "& .sd_loader": {
            width: "300px !important",
            height: "300px !important",
            [theme.breakpoints.down("xs")]: {
                width: "180px !important",
                height: "180px !important",
            },
            "& > div": {
                width: "250px !important",
                height: "250px !important",
                [theme.breakpoints.down("xs")]: {
                    width: "150px !important",
                    height: "150px !important",
                },
            },
            "& svg": {
                "& circle": {
                    strokeWidth: "1px"
                }
            }
        },
        "& p": {
            wordBreak: "break-word",
            "&.ntoe-limit" : {
                textAlign: "right"
            }
        }
    },
    modalFooter: {
        ...modalStyle(theme).modalFooter,
        marginTop: theme.spacing(2),
        justifyContent: "space-between !important",
        MsFlexPack: "space-between !important",
        WebkitBoxPack: "space-between !important",
    },
    filesList: {
        listStyleType: "none",
        paddingLeft: "0px",
        "& li": {
            fontSize: "12px",
            fontWeight: "bold"
        }
    },
    modalCloseButtonCustom: {
        top: "16px !important",
        right: "16px !important",
        position: "absolute !important",
        padding: "10px",
        "& svg": {
            marginRight: "0px"
        }
    },
    modalEditButtonCustom: {
        top: "16px !important",
        left: "16px !important",
        position: "absolute !important",
        padding: "10px",
        margin: "-1rem auto -1rem -1rem",
        "& svg": {
            marginRight: "0px"
        }
    },
});
  
export default renameFileModalStyle;
  