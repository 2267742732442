import { dangerColor, whiteColor, blackColor } from "../material-kit-pro-react.jsx";
import modalStyle from "../material-kit-pro-react/modalStyle.jsx";

const deleteFileModalStyle = theme => ({
    ...modalStyle(theme),
    modalRoot: {
        overflow: "auto",
        display: "block"
    },
    modal: {
        ...modalStyle(theme).modal,
        maxWidth: "550px !important",
        padding: "25px 28px 30px",
    },
    modalHeader: {
        padding: "0px",
        position: "relative",
    },
    modalTitle: {
        ...modalStyle(theme).modalTitle,
        textAlign: "left",
        fontWeight: "600",
    },
    modalCloseButtonCustom: {
        position: "absolute",
        top: "0px",
        right: "0px",
        margin: "0px",
        padding: "0px",
        "& svg": {
            marginRight: "0px"
        }
    },
    modalBody: {
        ...modalStyle(theme).modalBody,
        paddingLeft: "0px",
        paddingRight: "0px",
        paddingTop: "25px",
        paddingBottom: "20px",
        textAlign: "left",
        "& p": {
            color: theme.customize.whiteBlackColor,
            opacity: "0.8",
            "&$danger": {
                color: dangerColor[0],
                opacity: "1",
            },
            marginBottom: "18px",
            "& span": {
                fontWeight: "700"
            },            
            "&.delete-note-1": {
                marginBottom: "0px",
                marginTop: "20px"
            }
        },
        "& .items-lists": {
            paddingLeft: "23px",
            "& ul": {
                marginBottom: "0px"
            },
            "& p": {
                marginBottom: "0px",
                lineHeight: "normal"
            }
        },
        "& h6": {
            textTransform: "none",
            marginBottom: "3px"
        },
        "& .custom-input": {
            marginTop: "20px"
        }
    },
    modalFooter: {
        ...modalStyle(theme).modalFooter,
        justifyContent: "end !important",
        MsFlexPack: "end !important",
        WebkitBoxPack: "end !important",
        padding: "0px",
    },
    instructionNoticeModal: {
        marginBottom: "25px"
    },
    imageNoticeModal: {
        maxWidth: "150px"
    },
    modalLarge: {
        [theme.breakpoints.up("md")]: {
        maxWidth: "800px"
        }
    },
    modalSmall: {
        [theme.breakpoints.up("sm")]: {
        width: "300px",
        margin: "auto"
        },
        margin: "0 auto"
    },
    modalSmallBody: {
        marginTop: "20px"
    },
    modalSmallFooterFirstButton: {
        margin: "0",
        paddingLeft: "16px",
        paddingRight: "16px",
        width: "auto"
    },
    modalSmallFooterSecondButton: {
        marginBottom: "0",
        marginLeft: "5px"
    },
    modalLogin: {
        maxWidth: "360px",
        overflowY: "visible",
        width: "100%",
        "& $modalCloseButton": {
        color: whiteColor,
        top: "-10px",
        right: "10px",
        textShadow: "none",
        position: "relative"
        },
        "& $modalHeader": {
        borderBottom: "none",
        paddingTop: "24px",
        paddingRight: "24px",
        paddingBottom: "0",
        paddingLeft: "24px"
        },
        "& $modalBody": {
        paddingBottom: "0",
        paddingTop: "0"
        },
        "& $modalFooter": {
        paddingBottom: "0",
        paddingTop: "0"
        }
    },
    modalLoginCard: {
        marginBottom: "0",
        margin: "0",
        "& $modalHeader": {
        paddingTop: "0"
        }
    },
    modalSignup: {
        maxWidth: "900px",
        width: "100%",
        "& $modalHeader": {
        paddingTop: "0"
        },
        "& $modalTitle": {
        textAlign: "center",
        width: "100%",
        marginTop: "0.625rem"
        },
        "& $modalBody": {
        paddingBottom: "0",
        paddingTop: "0"
        }
    },
    modalSignupCard: {
        padding: "40px 0",
        margin: "0"
    },
    buttonLoader: {
        "&.sd_loader": {
            width: "17px",
            height: "17px",
            color: whiteColor
        }
    },
    snackbar: {
        "& .MuiPaper-root": {
            backgroundColor: blackColor
        }
    }
});

export default deleteFileModalStyle;
