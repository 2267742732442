import React from 'react';
import axios from "axios";
import Api from "../../../assets/js/utils/Api";
import PasswordMatch from "../../../assets/js/utils/PasswordMatch";
import LoaderComponent from '../../components/Loader'
import withStyles from "@material-ui/core/styles/withStyles";
import CustomInput from "../../components/CustomInput/CustomInput.jsx";
import GridItem from "../../components/Grid/GridItem.jsx";
import GridContainer from "../../components/Grid/GridContainer.jsx";
import Button from "../../components/CustomButtons/Button.jsx";
import Card from "../../components/Card/Card.jsx";
import CardBody from "../../components/Card/CardBody.jsx";
// import InfoArea from "../../components/InfoArea/InfoArea.jsx";
import InputAdornment from "@material-ui/core/InputAdornment";
import CustomSelect from "../../components/CustomInput/CustomSelect";
import DatePicker from "../../components/CustomInput/DatePicker";
import BackgroundSlider from "../../components/Slider/BackgroundSlider";

// @material-ui/icons
// import Timeline from "@material-ui/icons/Timeline";
// import Code from "@material-ui/icons/Code";
// import Group from "@material-ui/icons/Group";
import EditIcon from "@material-ui/icons/Edit";
import CalenderIcon from "@material-ui/icons/DateRange";

import signupPageStyle from "../../../assets/jss/material-kit-pro-react/views/signupPageStyle.jsx";
import { helper } from '../../../assets/js/utils/Element.js';

const BusinessInfo = class extends React.Component {
    constructor(props){
        super(props);

        this.store = this.props.store;
        this.history = this.props.history;
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

        this.state = {
            businessType: '',
            keywords: '',
            employeesCount: '',
            establishedDate: '',
            website: '',
            dob: '',
            occupation: "",
            address1: "",
            address2: "",
            countryId: "",
            stateId: "",
            cityId: "",
            loading: false,
            validation: {
                businessType: '',
                keywords: '',
                employeesCount: '',
                occupation: '',
                countryId: '',
                isValid: false
            },
            stepKey: 0,
            steps: ['basic', 'additional'],
            countries: [],
            states: [],
            cities: [],
            loadingCountries: false,
            loadingStates: false,
            loadingCities: false,
        };

        this.businessTypes = Api.getBusinessTypes();
        this.employeesNo = ['1-5', '6-10', '11-20', '21-50', '51-100', '100+'];
        this.occupationOptions = Api.getOccupationOptions();
    }
    componentDidMount(){
        this.loadCountries();
    }
    componentWillUnmount(){
        if (this.cancelToken) {
            this.cancelToken.cancel('Request Cancelled')
        }
    }
    loadCountries(){
        const that = this;
        const source = axios.CancelToken.source();
        this.cancelToken = source;
        that.setState({
            cancelToken: source,
            loadingCountries: true,
            countries: []
        });
        Api.getCountries(source).then(data => {
            that.setState({
                countries: data.response,
                loadingCountries: false
            });
        }).catch(err => {
            console.log(err);
        });
    }
    onCountryChange(e){
        const country = e.target.value;
        this.loadStates(country);
    }
    loadStates(country){
        const { countryId } = this.state;
        if(countryId === country){
            return;
        }
        const that = this;
        const source = axios.CancelToken.source();
        this.cancelToken = source;
        that.setState({
            cancelToken: source,
            loadingStates: true,
            countryId: country,
            states: [],
            stateId: ""
        });
        Api.getStates(country, source).then(data => {
            that.setState({
                states: data.response,
                loadingStates: false
            });
        }).catch(err => {
            console.log(err);
        });
    }
    onStateChange(e){
        const state = e.target.value;
        this.loadCities(state);
    }
    loadCities(state){
        const { stateId } = this.state;
        if(stateId === state){
            return;
        }
        const that = this;
        const source = axios.CancelToken.source();
        this.cancelToken = source;
        const { countryId } = this.state;
        that.setState({
            cancelToken: source,
            loadingCities: true,
            stateId: state,
            cities: [],
            cityId: ""
        });
        Api.getCities(countryId, state, source).then(data => {
            that.setState({
                cities: data.response,
                loadingCities: false
            });
        }).catch(err => {
            console.log(err);
        });
    }
    handleChange(e, name){
        let state = {};
        state[name] = e.target.value;
        if(name === "email"){
            state[name] = e.target.value.toLowerCase();
        }
        this.setState(state);
    }
    handleDateChange(date, name) {
        try{
            let parsedDate = new Date(date);
            if(parsedDate === "Invalid Date"){
                return;
            }
            this.setState({
                [name]: parsedDate.toISOString()
           });
        }catch(e){ console.log(e); }        
    }
    handleCheckbox(e, name){
        let state = {};
        state[name] = e.target.checked;
        this.setState(state);
    }
    handleSubmit(e){
        const { user } = this.store.getState();
        e.preventDefault();
        const isValid = this.validateAdditionalInfo();
        if(!isValid || this.state.loading){
            return;
        }
        let values = this.state;
        const requestData = {
            businessType: values.businessType,
            keywords: values.keywords,
            employeesCount: values.employeesCount,
            establishedDate: values.establishedDate,
            website: values.website,
            dob: values.dob,
            occupation: values.occupation,
            address1: values.address1,
            address2: values.address2,
            countryId: values.countryId,
            stateId: values.stateId,
            cityId: values.cityId
        };
        const source = axios.CancelToken.source();
        Api.businessInfo(requestData, source).then(data => {
            this.setState({
                loading: false, 
                requestCode: true,
                user: data.user
            });

            this.history.push(helper.getHomePageUrl(user));
        }).catch(err => {
            if(typeof(err) === "object" && err.hasOwnProperty("message")){
                this.setState({
                    loading: false, 
                    showError: true, 
                    cancelToken: null,
                    errorMessage: err.message,
                    advancedFields: false
                });
            }
        });
        this.setState({loading: true, showError: false, cancelToken: source});
    }
    validateForm(){
        let validation = {
            email: "success",
            password: "success",
            passwordConfirm: "success",
            isValid: true
        };

        var emailRegex = /\S+@\S+\.\S+/;
        if(this.state.email.length <= 0 || !emailRegex.test(this.state.email)){
            validation.email = "error";
            validation.isValid = false;
        }
        
        const that = this;
        const check = PasswordMatch.check(this.state.password,function(requireLowerletter, requireUpperletter, requireNumber, requireSymbol, requireLength){
            that.setState({
              showPasswordErrors: true,
              requireLowerletter: requireLowerletter,
              requireUpperletter: requireUpperletter,
              requireNumber: requireNumber,
              requireSymbol: requireSymbol,
              requireLength: requireLength
            });
        });
        if(this.state.password.length <= 0 || check === false){
            validation.password = "error";
            validation.isValid = false;
        }
        if(this.state.passwordConfirm.length <= 0 || this.state.password !== this.state.passwordConfirm){
            validation.passwordConfirm = "error";
            validation.isValid = false;
        }

        if(this.state.timezone.length <= 2){
            validation.timezone = "error";
            validation.isValid = false;
        }

        this.setState({validation: validation});
        return validation.isValid;
    }
    validateAdvForm(){
        let validation = this.state.validation;
        validation.businessType= "success";
        validation.keywords= "success";
        validation.employeesCount= "success";

        if(this.state.businessType.length <= 0){
            validation.businessType = "error";
            validation.isValid = false;
        }
        if(this.state.keywords.length <= 0){
            validation.keywords = "error";
            validation.isValid = false;
        }
        if(this.state.employeesCount.length <= 0){
            validation.employeesCount = "error";
            validation.isValid = false;
        }

        this.setState({validation: validation});
        return validation.isValid;
    }
    renderErrorMessages(){
        const { errorMessage } = this.state;
        if(typeof(errorMessage) === "object"){
            let errorMessages = [];
            let key = 0;
            for(const attrib in errorMessage){
                const message = errorMessage[attrib];
                errorMessages.push(<GridItem key={key} className={"passwordCheck-notValid-customizable"}>
                    <span aria-hidden="true" className="validation-error-symbol check-lowerletter">&#x2716;</span>
                    <span className="checkPasswordText-lowerletter">{message}</span>
                </GridItem>);
                key++;
            }
            return errorMessages;
        }
        return <GridItem className={"passwordCheck-notValid-customizable"}>
            <span aria-hidden="true" className="validation-error-symbol check-lowerletter">&#x2716;</span>
            <span className="checkPasswordText-lowerletter">{errorMessage}</span>
        </GridItem>;
    }
    renderBasicInfo(){
        const { classes } = this.props;
        const { validation } = this.state;
        return (
            <div>
                <CustomSelect 
                    success={validation.businessType === "success"}
                    error={validation.businessType === "error"}
                    formControlProps={{
                        fullWidth: true
                    }}
                    labelText="Business Type"
                    selectProps={{
                        onChange: (e) => this.handleChange(e,"businessType"),
                        value: this.state.businessType
                    }}
                    inputProps={{
                        name: "businessType",
                        id: "input-businessType",
                        className: classes.alignLeft
                    }}
                    items={this.businessTypes}
                    itemLabel="label"
                    itemValue="id"
                />
                <CustomInput
                    success={validation.keywords === "success"}
                    error={validation.keywords === "error"}
                    formControlProps={{
                        fullWidth: true,
                        className: classes.customFormControlClasses
                    }}
                    id="input-keywords"
                    inputProps={{
                        onChange: (e) => this.handleChange(e, 'keywords'),
                        name: "keywords",
                        value: this.state.keywords,
                        startAdornment: (
                            <InputAdornment position="start" className={classes.inputAdornment} >
                                <EditIcon className={classes.inputAdornmentIcon}/>
                            </InputAdornment>
                        ),
                        placeholder:"Product or Service Keywords"
                    }}
                />
                <CustomSelect 
                    success={validation.employeesCount === "success"}
                    error={validation.employeesCount === "error"}
                    formControlProps={{
                        fullWidth: true
                    }}
                    labelText="Number of Employees"
                    selectProps={{
                        onChange: (e) => this.handleChange(e,"employeesCount"),
                        value: this.state.employeesCount
                    }}
                    inputProps={{
                        name: "employeesCount",
                        id: "input-employeesCount",
                        className: classes.alignLeft
                    }}
                    items={this.employeesNo}
                />
                <DatePicker
                    labelText={null}
                    id="input-establishedDate"
                    value={this.state.establishedDate}
                    disableFuture={true}
                    onChange={(date) => this.handleDateChange(date, 'establishedDate')}
                    inputProps={{
                        placeholder:"Date Business Established",
                        startAdornment: (
                            <InputAdornment position="start" className={classes.inputAdornment} >
                                <CalenderIcon className={classes.inputAdornmentIcon}/>
                            </InputAdornment>
                        ),
                    }}
                    formControlProps={{
                        fullWidth: true,
                        className: classes.customFormControlClasses
                    }}
                />
                <CustomInput
                    formControlProps={{
                        fullWidth: true,
                        className: classes.customFormControlClasses
                    }}
                    id="input-website"
                    inputProps={{
                        onChange: (e) => this.handleChange(e, 'website'),
                        name: "website",
                        value: this.state.website,
                        startAdornment: (
                            <InputAdornment position="start" className={classes.inputAdornment} >
                                <EditIcon className={classes.inputAdornmentIcon}/>
                            </InputAdornment>
                        ),
                        placeholder:"Website"
                    }}
                />
            </div>
        );
    }
    renderAdditionalInfo(){
        const { classes } = this.props;
        const { validation } = this.state;
        return (
            <div>                
                <DatePicker
                    labelText={null}
                    id="input-dob"
                    value={this.state.dob}
                    disableFuture={true}
                    onChange={(date) => this.handleDateChange(date, 'dob')}
                    inputProps={{
                        placeholder:"Date of Birth",
                        startAdornment: (
                            <InputAdornment position="start" className={classes.inputAdornment} >
                                <CalenderIcon className={classes.inputAdornmentIcon}/>
                            </InputAdornment>
                        ),
                    }}
                    formControlProps={{
                        fullWidth: true,
                        className: classes.customFormControlClasses
                    }}
                />
                <CustomSelect 
                    success={validation.occupation === "success"}
                    error={validation.occupation === "error"}
                    formControlProps={{
                        fullWidth: true
                    }}
                    labelText="What is Your Occupation?"
                    selectProps={{
                        onChange: (e) => this.handleChange(e,"occupation"),
                        value: this.state.occupation
                    }}
                    inputProps={{
                        name: "occupation",
                        id: "input-occupation",
                        className: classes.alignLeft
                    }}
                    items={this.occupationOptions}
                />
                <CustomInput
                    formControlProps={{
                        fullWidth: true,
                        className: classes.customFormControlClasses
                    }}
                    id="input-address1"
                    inputProps={{
                        onChange: (e) => this.handleChange(e, 'address1'),
                        name: "address1",
                        value: this.state.address1,
                        startAdornment: (
                            <InputAdornment position="start" className={classes.inputAdornment} >
                                <EditIcon className={classes.inputAdornmentIcon}/>
                            </InputAdornment>
                        ),
                        placeholder:"Address1"
                    }}
                />
                <CustomInput
                    formControlProps={{
                        fullWidth: true,
                        className: classes.customFormControlClasses
                    }}
                    id="input-address2"
                    inputProps={{
                        onChange: (e) => this.handleChange(e, 'address2'),
                        name: "address2",
                        value: this.state.address2,
                        startAdornment: (
                            <InputAdornment position="start" className={classes.inputAdornment} >
                                <EditIcon className={classes.inputAdornmentIcon}/>
                            </InputAdornment>
                        ),
                        placeholder:"Address2"
                    }}
                />
                {this.renderCountries()}
            </div>
        );
    }
    renderCountries(){
        const { classes } = this.props;
        const { validation } = this.state;
        const { countries, states, cities, loadingCountries, loadingStates, loadingCities } = this.state;
        return (
            <>
                {
                    countries.length > 0 ?
                        <CustomSelect 
                            formControlProps={{
                                fullWidth: true
                            }}
                            labelText="Country"
                            success={validation.countryId === "success"}
                            error={validation.countryId === "error"}
                            selectProps={{
                                onChange: (e) => this.onCountryChange(e),
                                value: this.state.countryId
                            }}
                            inputProps={{
                                name: "countryId",
                                id: "input-countryId",
                                className: classes.alignLeft
                            }}
                            items={countries}
                            itemLabel="name"
                            itemValue="id"
                        />
                    :
                        loadingCountries ?
                            <LoaderComponent color="custom" align="left" />
                        :
                        <></>
                }
                {
                    states.length > 0 ?
                        <CustomSelect 
                            formControlProps={{
                                fullWidth: true
                            }}
                            labelText="State / Province"
                            success={validation.stateId === "success"}
                            error={validation.stateId === "error"}
                            selectProps={{
                                onChange: (e) => this.onStateChange(e),
                                value: this.state.stateId
                            }}
                            inputProps={{
                                name: "stateId",
                                id: "input-stateId",
                                className: classes.alignLeft
                            }}
                            items={states}
                            itemLabel="name"
                            itemValue="id"
                        />
                    :
                        loadingStates ?
                            <LoaderComponent color="custom" align="left" />
                        :
                        <></>
                }
                {
                    cities.length > 0 ?
                        <CustomSelect 
                            formControlProps={{
                                fullWidth: true
                            }}
                            labelText="City"
                            selectProps={{
                                onChange: (e) => this.handleChange(e, "cityId"),
                                value: this.state.cityId
                            }}
                            inputProps={{
                                name: "cityId",
                                id: "input-cityId",
                                className: classes.alignLeft
                            }}
                            items={cities}
                            itemLabel="name"
                            itemValue="id"
                        />
                    :
                        loadingCities ?
                            <LoaderComponent color="custom" align="left" />
                        :
                        <></>
                }
            </>
        )
    }
    renderSteps(){
        const { stepKey } = this.state;
        let content = null;
        switch(stepKey){
            case 0:
            default:
                content = this.renderBasicInfo();
                break;
            case 1:
                content = this.renderAdditionalInfo();
                break;
        }
        return content;
    }
    moveStep(stepKey){
        this.setState({stepKey: stepKey});
    }
    renderStepTitle(){
        const { stepKey } = this.state;
        let title = null;
        switch(stepKey){
            case 0:
            default:
                title = "Describe Your Business";
                break;
            case 1:
                title = "Tell Us More About You";
                break;
        }
        return title;
    }
    validateSteps(nextStep){
        const { stepKey } = this.state;
        switch(stepKey){
            case 0:
            default:
                this.validateBasicInfo(nextStep);
                break;
            case 1:
                this.validateAdditionalInfo(nextStep);
                break;
        }
    }
    validateBasicInfo(nextStep){
        const { stepKey } = this.state;
        let validation = {
            businessType: 'success',
            keywords: 'success',
            employeesCount: 'success',
            establishedDate: 'success',
            isValid: true
        };

        if(this.state.businessType.length <= 0){
            validation.businessType = "error";
            validation.isValid = false;
        }
        if(this.state.keywords.length <= 3){
            validation.keywords = "error";
            validation.isValid = false;
        }
        if(this.state.employeesCount.length <= 0){
            validation.employeesCount = "error";
            validation.isValid = false;
        }
        this.setState({
            validation: {
                ...this.state.validation,
                ...validation
            },
            stepKey: (validation.isValid ? nextStep : stepKey)
        });
        return validation.isValid;
    }
    validateAdditionalInfo(){
        let validation = {
            occupation: 'success',
            countryId: 'success',
            isValid: true
        };

        if(this.state.occupation.length <= 0){
            validation.occupation = "error";
            validation.isValid = false;
        }
        if(this.state.countryId.length <= 0){
            validation.countryId = "error";
            validation.isValid = false;
        }
        this.setState({
            validation: {
                ...this.state.validation,
                ...validation
            },
        });
        return validation.isValid;
    }
    render() {
        const { classes } = this.props;
        const { loading, showError } = this.state;
        const { stepKey, steps } = this.state;
        const { authorized } = this.store.getState();
        if(!authorized){
            return <></>
        }
        const nextStep = ((stepKey + 1 === steps.length) ? null : (stepKey + 1));
        return (
            <div>
                <div className={classes.container}>
                    <GridContainer justify="center">
                        <GridItem xs={12} sm={10} md={6}>
                            <Card className={classes.cardSignup}>
                                <h2 className={classes.cardTitle}>{this.renderStepTitle()}</h2>
                                <CardBody>
                                    <GridContainer justify="center">
                                        <GridItem xs={12} sm={12} md={12}>
                                            {
                                                showError ?
                                                    this.renderErrorMessages()
                                                :
                                                <></>
                                            }
                                            <form className={classes.form} onSubmit={this.handleSubmit}>
                                                {this.renderSteps()}
                                                <div className={classes.textCenter+" "+(stepKey ? classes.alignButtons : '')}>
                                                    {
                                                        loading ?
                                                            <LoaderComponent />
                                                        :
                                                            stepKey ?
                                                                <>
                                                                    <Button round color="primary" onClick={() => this.moveStep((stepKey - 1))}>Back</Button>
                                                                    {
                                                                        nextStep ?
                                                                            <Button round color="primary" onClick={() => this.validateSteps(nextStep)}>Continue</Button>
                                                                        :
                                                                            <Button round type="submit" color="primary">Finish</Button>
                                                                    }
                                                                </>
                                                            :
                                                            <Button round color="primary" onClick={() => this.validateSteps(nextStep)}>Continue</Button>
                                                    }                                        
                                                </div>
                                            </form>
                                        </GridItem>
                                    </GridContainer>
                                </CardBody>
                            </Card>
                        </GridItem>
                    </GridContainer>
                </div>
                <BackgroundSlider store={this.store} />
            </div>
        )
    }
}

export default withStyles(signupPageStyle)(BusinessInfo);