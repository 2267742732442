import React from "react";
import Api from "../../../assets/js/utils/Api";
import axios from "axios";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import Button from "../CustomButtons/Button.jsx";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Close from "@material-ui/icons/Close";
import LoaderComponent from '../Loader';
import GridItem from "../Grid/GridItem.jsx";
import MaterialIcon from "@mdi/react";
import { mdiAccountGroup } from '@mdi/js';

import accountAllowanceModalStyle from "../../../assets/jss/user/accountAllowanceModalStyle";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
Transition.displayName = "Transition";

const AccountAllowanceModal = class extends React.Component {
    constructor(props){
        super(props);
        this.store = this.props.store;
        this.history = this.props.history;
        const { user } = this.store.getState();

        let accountAllowance = 'any';
        if(user.hasOwnProperty('businessUser') && user.businessUser.account_allowance){
            accountAllowance = user.businessUser.account_allowance;
        }
        const addedUser = this.props.user;

        let emails = [];
        if(accountAllowance === 'wildcard' && user.businessUser.hasOwnProperty('emails') && user.businessUser.emails.length > 0){
            emails = this.getEmails(user.businessUser.emails);
        }
        if(addedUser !== null && addedUser.hasOwnProperty("emails")){
            emails = this.getEmails(addedUser.emails);
        }

        this.state = {
            newUser: null,
            accountAllowance: "wildcard",
            emails: emails,
            email: emails.join("\n"),
            user: addedUser,
            saving: false,
            response: null,
            success: false,
            cancelToken: null,
            showError: false,
            errorMessage: "",
            validation: {
                email: "",
                isValid: false
            },
            requireLowerletter: false,
            requireUpperletter: false,
            requireNumber: false,
            requireSymbol: false,
            requireLength: false,
        };

        this.updateAccountAlloance = this.updateAccountAlloance.bind(this);
        this.onEmailKeyUp = this.onEmailKeyUp.bind(this);
    }
    getEmails(emails){
        let emailsArray = []
        emails.map(email => {
            emailsArray.push(email.email);
            return null;
        });
        
        return emailsArray;
    }
    onEmailKeyUp(event, value = null) {
        if(event && event.nativeEvent.keyCode !== 13){
            return;
        }
        let { email } = this.state;
        if(value !== null){
            email = value;
        }
        if(email.length < 0){
            return;
        }
        let emails = email.trim().split(/\s*[\r\n]+\s*/g);
        if(emails.length <= 0){
            return;
        }

        let uniqueEmails = [];
        emails.map(newEmail => {
            if(newEmail.length <= 0 || uniqueEmails.indexOf(newEmail) !== -1){
                return null;
            }
            uniqueEmails.push(newEmail);
            return null;
        });
        emails.push(email);
        this.setState({
            emails: uniqueEmails,
            email: email
        });
    } 
    removeRecipient(e, email){
        e.preventDefault();
        let emails = this.state.emails;
        if(emails.length <= 0 || email.length <= 0){
            return;
        }
        const index = emails.indexOf(email);
        emails.splice(index, 1);
        this.setState({emails: emails, email: emails.join("\n")});
    }
    validateForm(){
        let validation = {
            email: 'success',
            isValid: true
        };

        if(this.state.accountAllowance === "wildcard"){
            if(this.state.emails.length <= 0){
                validation.email = "error";
                validation.isValid = false;
            }
        }
        
        this.setState({validation: validation});
        return validation.isValid;
    }
    updateAccountAlloance(){
        // const isValid = this.validateForm();
        // if(!isValid){
        //     return;
        // }

        const { emails, user } = this.state;
        const requestData = {
            accountAllowance: "wildcard",
            emails: emails
        };
        
        const source = axios.CancelToken.source();
        this.setState({
            saving: true,
            cancelToken: source,
            showError: false,
        });
        
        const that = this;
        let userId = '';
        if(user !== null){
            userId = user.id;
        }
        Api.updateBusinessAccountAllowance(userId, requestData, source).then(data => {
            let newUser = Api.prepareMemberObject(data);
            that.setState({
                success: true,
                saving: false,
                newUser: newUser
            });
        }).catch(err => {
            if(typeof(err) === "object" && err.hasOwnProperty("message") && err.message !== 'Request Cancelled'){
                this.setState({
                    saving: false,
                    showError: true,
                    errorMessage: err.message
                });
            }
        });
    }
    renderErrorMessages(){
        const { errorMessage } = this.state;
        if(typeof(errorMessage) === "object"){
            let errorMessages = [];
            let key = 0;
            for(const attrib in errorMessage){
                const message = errorMessage[attrib];
                errorMessages.push(<GridItem key={key} className={"passwordCheck-notValid-customizable"}>
                    <span aria-hidden="true" className="validation-error-symbol check-lowerletter">&#x2716;</span>
                    <span className="checkPasswordText-lowerletter">{message}</span>
                </GridItem>);
                key++;
            }
            return errorMessages;
        }
        return <GridItem className={"passwordCheck-notValid-customizable"}>
            <span aria-hidden="true" className="validation-error-symbol check-lowerletter">&#x2716;</span>
            <span className="checkPasswordText-lowerletter">{errorMessage}</span>
        </GridItem>;
    }
    handleChange(e, name){
        let state = {};
        state[name] = e.target.value;
        if(name === "email"){
            return this.onEmailKeyUp(null, e.target.value);
        }
        this.setState(state);
    }
    handleAccountAllowance(e, value){
        let state = {};
        state['accountAllowance'] = value;
        if(value !== 'wildcard'){
            state['emails'] = [];
            state['email'] = ''
        }
        this.setState(state);
    }
    renderForm(){
        const { saving, showError, emails } = this.state;
        const { classes } = this.props;

        return(
            <div className={classes.addUserContainer}>
                <div className={classes.addUserForm}>
                    <div>
                        <div className={classes.alignLeft}>
                            <div>
                                <div className="formElement">
                                    <p className="recipients-description">Enter the email accounts of the users that will allow member(s) of your group to gain access to.</p>
                                    {
                                        showError ?
                                            this.renderErrorMessages()
                                        :
                                        <></>
                                    }
                                    {
                                        emails.length > 0 ?
                                            <div className={"react-tagsinput "+classes.tagsInput}>
                                                <div className="users-icon">
                                                    <MaterialIcon path={mdiAccountGroup} />
                                                </div>
                                                <div className="users-lists">
                                                    {
                                                        emails.map((email, key) => {
                                                            return (
                                                                <span className="react-tagsinput-tag rose" key={key}>
                                                                    {email}
                                                                    <a href="/" onClick={(e) => this.removeRecipient(e, email)}>&nbsp;</a>
                                                                </span>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        :
                                        <></>
                                    }
                                    <textarea
                                        onChange={(e) => this.handleChange(e, 'email')}
                                        className={classes.textarea}
                                        value={this.state.email}
                                    ></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={classes.transferFooter+" "+classes.fileSendingFormFooter}>
                        <Button color="custom" onClick={this.updateAccountAlloance} type="button">
                            {
                                saving ?
                                    <LoaderComponent color="white" align="left" saving={true} />
                                :
                                    "Save Changes"
                            }
                        </Button>
                    </div>
                </div>
            </div>
        )
    }
    render() {
        const { success } = this.state;
        const { classes } = this.props;

        return (
            <Dialog
                classes={{
                    root: classes.modalRoot,
                    paper: classes.modal
                }}
                open={this.props.open}
                TransitionComponent={Transition}
                keepMounted
                aria-labelledby="userDetails-slide-title"
                aria-describedby="userDetails-slide-description"
            >
                <DialogContent
                    id="userDetails-slide-description"
                    className={classes.modalBody}
                    >
                        <Button
                            simple
                            className={classes.modalCloseButton+" "+classes.modalCloseButtonCustom}
                            key="close"
                            aria-label="Close"
                            onClick={() => this.props.onClose()}
                        >
                            {" "}
                            <Close className={classes.modalClose} />
                        </Button>
                        {
                            success ?
                                <>
                                    <p className={classes.successMessage}>Account Access Updated Successfully.</p>
                                    <Button color="custom" onClick={() => this.props.onSuccess(this.state.newUser)}>
                                        Close
                                    </Button>
                                </>
                            :
                                this.renderForm()
                        }
                </DialogContent>      
            </Dialog>
        );
    }
};

AccountAllowanceModal.defaultProps = {
    open: false,
    user: null
};
AccountAllowanceModal.propTypes = {
    onClose: PropTypes.func,
    onSuccess: PropTypes.func,
    open: PropTypes.bool,
};
export default withStyles(accountAllowanceModalStyle)(AccountAllowanceModal);
