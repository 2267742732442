import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import Button from "../CustomButtons/Button.jsx";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import archivesInfoModalStyle from "../../../assets/jss/user/archivesInfoModalStyle";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
Transition.displayName = "Transition";

const HousekeepingInfoModal = class extends React.Component {
    constructor(props){
        super(props);
        this.store = this.props.store;
    }
    getInfoText(type){
        let infoTypeText = "";
        switch(type){
            case 1:
                infoTypeText = "When you delete files that are older than the specified duration, ALL associated links will also be deleted. This will delete ALL older files and their links in your Transfers, My Docs and Archives.";
                break;
            case 2:
                infoTypeText = "When you delete files that have been downloaded and are older than the specified duration, ALL associated links will also be deleted. This will delete ALL older files that have been downloaded and their links in your Transfers, My Docs and Archives.";
                break;
            case 3:
                infoTypeText = "When you delete links that are older than the specified duration, you have the “option” to delete the associated files in your Transfers, My Docs and Archives. Important: If you choose to delete the associated files, ALL other links associated to those files will also be deleted.";
                break;
            case 4:
                infoTypeText = "When you delete links that are expired and are older than a specified duration, you have the “option” to delete the associated files in your Transfers, My Docs and Archives. Important: If you choose to delete the associated files, ALL other links associated to those files will also be deleted.";
                break;
            default:
                break;
        }

        return infoTypeText;
    }
    render() {
        const { classes, type } = this.props;
        return (
        <Dialog
            classes={{
            root: classes.modalRoot,
            paper: classes.modal
            }}
            open={this.props.open}
            TransitionComponent={Transition}
            keepMounted
            aria-labelledby="deleteFile-slide-title"
            aria-describedby="deleteFile-slide-description"
        >
            <DialogTitle
                id="attachments-modal-slide-title"
                disableTypography
                className={classes.modalHeader}
                >
            </DialogTitle>
            <DialogContent
            id="deleteFile-slide-description"
            className={classes.modalBody}
            >
                <p>{this.getInfoText(type)}</p>
            </DialogContent>
            <DialogActions
            className={classes.modalFooter + " " + classes.justifyContentCenter}
            >
            <Button onClick={() => this.props.onClose()} color="primary" round>Close</Button>
            </DialogActions>        
        </Dialog>
        );
    }
};

HousekeepingInfoModal.defaultProps = {
  open: false,
};
HousekeepingInfoModal.propTypes = {
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
  open: PropTypes.bool,
};
export default withStyles(archivesInfoModalStyle)(HousekeepingInfoModal);
