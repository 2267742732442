/*!

=========================================================
* Material Kit PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import {
    primaryColor,
    container,
    cardTitle,
    whiteColor,
    blackColor,
    hexToRgb,
    dangerColor,
    grayColor
  } from "../../material-kit-pro-react.jsx";
  
  import customCheckboxRadioSwitchStyle from "../customCheckboxRadioSwitchStyle.jsx";
  
  const signupPageStyle = theme => ({
      container: {
          ...container,
          zIndex: "2",
          position: "relative",
          paddingTop: "5.5em",
      },
      pageHeader: {
          minHeight: "100vh",
          height: "auto",
          display: "inherit",
          position: "relative",
          margin: "0",
          padding: "0",
          border: "0",
          alignItems: "center",
          "&:before": {
              background: "rgba(" + hexToRgb(blackColor) + ", 0.5)"
          },
          "&:after": {
              background:
                  "linear-gradient(60deg,rgba(" +
                  hexToRgb(primaryColor[4]) +
                  ",.56),rgba(" +
                  hexToRgb(primaryColor[5]) +
                  ",.95))"
          },
          "&:before,&:after": {
              position: "absolute",
              zIndex: "1",
              width: "100%",
              height: "100%",
              display: "block",
              left: "0",
              top: "0",
              content: '""'
          }
      },
      cardSignup: {
          borderRadius: "6px",
          marginBottom: "100px",
          padding: "40px 0px",
        //   backgroundColor: "rgba(0, 0, 0, 0.3)",
          [theme.breakpoints.down("sm")]: {
              marginBottom: "80px",
          },
          [theme.breakpoints.down("xs")]: {
              marginBottom: "50px",
          },
          marginTop: "0px",
        //   "& >h2, .MuiSelect-icon, label, p, .MuiInput-input,  .MuiInput-input::placeholder": {
        //       color: whiteColor+" !Important" 
        //   },
        "&.basic-signup": {
            "& label": {
                top: "10px"
            }
        },
        "& .viewPasswordIcon": {
            cursor: "pointer",
            marginRight: "0px"
        },
        "&.validate": {
            "& .viewPasswordIcon": {
                marginRight: "22px"
            },
        }
      },
    //   inputAdornmentIcon: {
    //       color: whiteColor+ "!important",
    //   },
      cardTitle: {
          ...cardTitle,
          textDecoration: "none",
          textAlign: "center !important",
          marginBottom: "0.75rem",
          fontWeight: "normal",
          fontSize: "33px"
      },
      ...customCheckboxRadioSwitchStyle,
      socials: {
          marginTop: "0",
          position: "absolute",
          width: "100%",
          transform: "none",
          left: "0",
          top: "0",
          height: "100%",
          lineHeight: "41px",
          fontSize: "20px"
      },
      textCenter: {
          textAlign: "center"
      },
      inputAdornment: {
          marginRight: "18px",
          position: "relative"
      },
      form: {
          margin: "0",
          "& label.MuiFormControlLabel-root": {
              width: "100%"
          }
      },
      infoArea: {
          padding: "0px 0px 20px !important"
      },
      block: {
          color: "inherit",
          padding: "0.9375rem",
          fontWeight: "500",
          fontSize: "12px",
          textTransform: "uppercase",
          borderRadius: "3px",
          textDecoration: "none",
          position: "relative",
          display: "block"
      },
      inlineBlock: {
          display: "inline-block",
          padding: "0px",
          width: "auto"
      },
      list: {
          marginBottom: "0",
          padding: "0",
          marginTop: "0"
      },
      left: {
          float: "left!important",
          display: "block",
          "&,& *,& *:hover,& *:focus": {
          color: whiteColor + "  !important"
          }
      },
      right: {
          padding: "15px 0",
          margin: "0",
          float: "right",
          "&,& *,& *:hover,& *:focus": {
          color: whiteColor + "  !important"
          }
      },
      icon: {
          width: "18px",
          height: "18px",
          top: "3px",
          position: "relative"
      },
      terms: {
          marginTop: "0px",
          marginBottom: theme.spacing(2),
          color: dangerColor[0]
      },
      anchor: {
          color: primaryColor[0],
        //   color: whiteColor,
          cursor: "pointer"
      },
      verifyError: {
          marginTop: theme.spacing(1)
      },
      modalRootCustom: {
          padding: "0px",
          [theme.breakpoints.down('xs')]: {
              top: "16px !important",
          }
      },
      modalSignup: {
          width: "100%",
          maxWidth: "900px",
          [theme.breakpoints.down('xs')]: {
              marginTop: "0px !important"
          }        
      },
      paperScrollPaperCustom: {
          height: "auto",
      },
      modalSignupCard: {
          margin: "0px",
          padding: "40px 0px",
          [theme.breakpoints.down('xs')]: {
              paddingTop: "0px !important"
          } 
      },
      alignLeft: {
          textAlign: "left"
      },
      verificationNote: {
          textAlign: "left",
          marginTop: "-15px",
          marginBottom: "0px"
      },
      recaptcha: {
        
      },
      hide: {
        display: "none"
      },
    selectAccountType: {
        "& .MuiFormControlLabel-root": {
            display: "flex",
            maxWidth: "300px",
            margin: "0px auto 10px auto",
            "& $label": {
                display: "block",
                fontWeight: "bold",
                textAlign: "left",
                color: blackColor,
            },
            "& p": {
                fontWeight: "normal",
                margin: "5px 0px",
                color: grayColor[7],
            }
        }
    },
    alignButtons: {
        display: "flex",
        justifyContent: "space-between"
    },
    businessLeagelHeader: {
        display: "flex",
        position: "fixed",
        width: "100%",
        zIndex: "1100",
        justifyContent: "space-between",
        padding: "0px 15px",
        alignItems: "center",
        "& img": {
            maxHeight: "60px",
            objectFit: "cover",
            objectPosition: "center",
            padding: "6px 8px"
        },
        "& p": {
            marginBottom: "0px",
            color: whiteColor
        }
    },
    backgroundSlider: {
        position: "fixed",
        top: "0px",
        width: "100%",
        height: "100%",
        "& .background-image": {
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            width: "100%",
            height: "100%",
            display: "block"
        },
        "& .banner-text-content": {
            height: "auto !important",
            position: "absolute",
            bottom: "21px",
            right: "160px",
            color: whiteColor,
            "& .banner-text": {
                maxWidth: "500px",
                margin: "0px auto",
                "& p": {
                    fontSize: "20px",
                    lineHeight: "28px",
                    marginBottom: "0px",
                    textShadow: "2px 2px 2px "+blackColor,
                }
            },
            [theme.breakpoints.down("md")]: {
                "& div.banner-text": {
                    "& p": {
                        fontSize: "18px",
                        lineHeight: "24px"
                    }
                },
            },
            [theme.breakpoints.down("sm")]: {
                display: "none",
            },
        }
    },
    aboutBusiness: {
        marginTop: "10px",
        color: whiteColor
    },
    alignRight: {
        textAlign: "right",
        "& p, & label": {
            textAlign: "left"
        },
        "& p.informationSafe": {
            textAlign: "right",
        }
    },
    applyButton: {
        marginLeft: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    termsError: {
        color: dangerColor[0]
    }
});
  
export default signupPageStyle;
  