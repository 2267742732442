import React from 'react'
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import Button from "../CustomButtons/Button.jsx";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import LoaderComponent from "../Loader.js";
import CustomInput from "../CustomInput/CustomInput.jsx";

import noteModalStyle from "../../../assets/jss/user/noteModalStyle.jsx";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});
Transition.displayName = "Transition";

const NoteModal = class extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            note: this.props.file.note ? this.props.file.note : "",
            noteLimit: '30000',
        }
    }
    getNoteLength(note){
        return (note ? note.length : 0);
    }
    handleChange(e, name){
        let value = e.target.value;
        if(value.length > this.state.noteLimit){
            value = value.slice(0, this.state.noteLimit);
        }

        let state = {};
        state[name] = value;
        this.setState(state);
    }
    render() {
        const { classes, saving, errorMessage } = this.props;
        const { note } = this.state;
        
        return (
            <Dialog
                classes={{
                    root: classes.modalRoot,
                    paper: classes.modal
                }}
                open={this.props.open}
                TransitionComponent={Transition}
                keepMounted
                aria-labelledby="noteFileModal-slide-title"
                aria-describedby="noteFileModal-slide-description"
            >
                <DialogTitle
                    id="noteFileModal-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                    >
                        {
                            errorMessage !== null ?
                                <></>
                            :
                            saving ?
                                <h4 className={classes.modalTitle+" "+classes.textCenter}>Please wait while we add note.</h4> 
                            :
                            <h4 className={classes.modalTitle+" "+classes.textCenter}>
                                {
                                    this.getNoteLength(note) > 0 ?
                                        <>Edit Note</>
                                    :
                                        <>Add a Note</>
                                }
                            </h4>
                        }
                </DialogTitle>
                <DialogContent
                    id="noteFileModal-slide-description"
                    className={classes.modalBody}
                    >
                        {
                            errorMessage !== null ?
                                <p className={classes.errorMessage}>{errorMessage}</p>
                            : saving ?
                                <LoaderComponent color="custom" align="center" />
                            :
                                <>
                                    <CustomInput
                                        formControlProps={{
                                            fullWidth: true,
                                        }}
                                        //labelText= {"Note"}
                                        id="input-note"
                                        inputProps={{
                                            onChange: (e) => this.handleChange(e, 'note'),
                                            name: "note",
                                            value: note,
                                            multiline: true,
                                            rows: 6,
                                        }}
                                    />
                                    <p className='ntoe-limit'>{ this.getNoteLength(note) }/{ this.state.noteLimit }</p>
                                </>
                        }
                </DialogContent>
                <DialogActions className={classes.modalFooter +" " +classes.justifyContentCenter}>
                    {
                        errorMessage !== null ?
                            <Button color="custom" onClick={() => this.props.onClose()} round>Cancel</Button>
                        : saving === false ?
                            <>
                                <Button color="custom" onClick={() => this.props.onSuccess(note)} round>Save</Button>
                                <Button color="white" onClick={() => this.props.onClose()} round>Cancel</Button>
                            </>
                        :
                            <></>
                    }
                </DialogActions>
            </Dialog>
        )
    }
}

NoteModal.defaultProps = {
    open: false,
    loadingMessage: "",
    successMessage: "",
    saving: false,
    errorMessage: null
}
NoteModal.propTypes = {
    onClose: PropTypes.func,
    open: PropTypes.bool,
    loadingMessage: PropTypes.string,
    saving: PropTypes.bool,
    errorMessage: PropTypes.string,
}
export default withStyles(noteModalStyle)(NoteModal);