import Api from '../../assets/js/utils/Api';

let initialState = {
    url: window.location.pathname,
    authorized: false,
    user: Api.getDefaultMemberObject(),
    mainLoading: false,
    customState: null
}

const simpleReducer = (state = initialState, action) => {
    if(!action.hasOwnProperty("state")){
        action.state = {};
    }
    switch (action.type) {
        case 'LOGOUT':
            action.state.authorized = false;
            action.state.mainLoading = false;
            action.state.user = Api.getDefaultMemberObject();
            initialState = Object.assign({}, state, action.state);
            return initialState;
        case 'LOGIN':
            action.state.authorized = true;
            action.state.mainLoading = false;
            initialState = Object.assign({}, state, action.state);         
            return initialState;
        case 'UPDATE_STATE':
            initialState = Object.assign({}, state, action.state);
            return initialState;
        case 'LOADING':
            initialState = Object.assign({}, state, {
                mainLoading: true
            });
            return initialState;
        default:
        return state
    }
}

export default simpleReducer;