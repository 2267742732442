import modalStyle from "../material-kit-pro-react/modalStyle.jsx";
  
const linkDetailModalStyle = theme => ({
    ...modalStyle(theme),
    textCenter: {
        textAlign: "center"
    },
    justifyContentCenter: {
        WebkitBoxPack: "center !important",
        MsFlexPack: "center !important",
        justifyContent: "space-between !important",
        alignSelf: "center !important",
        width: "100%",
        maxWidth: "300px"
    },
    modal: {
        ...modalStyle(theme).modal,
        maxWidth: "400px !important"
    },
    modalBody: {
        ...modalStyle(theme).modalBody,
        paddingBottom: "0px",
        paddingTop: "20px",
        "&>a": {
            display: "block",
            wordBreak: "break-word",
            whiteSpace: "pre-wrap",
        },
        "& .sd_loader": {
            width: "300px !important",
            height: "300px !important",
            [theme.breakpoints.down("xs")]: {
                width: "180px !important",
                height: "180px !important",
            },
            "& > div": {
                width: "250px !important",
                height: "250px !important",
                [theme.breakpoints.down("xs")]: {
                    width: "150px !important",
                    height: "150px !important",
                },
            },
            "& svg": {
                "& circle": {
                    strokeWidth: "1px"
                }
            }
        },
        "& ul": {
            listStyleType: "none",
            paddingLeft: "0px",
            "& li": {
                marginBottom: "5px",
                paddingBottom: "5px",
                borderBottom: "1px solid "+theme.customize.borderColorModal,
                display: "flex",
                "&>span": {
                    fontWeight: "bold",
                    "&:after": {
                        content: '":"',
                        marginRight: "5px",
                        marginLeft: "2px",
                    }
                },
                "& button": {
                    width: "30px",
                    height: "17px",
                    margin: "0px",
                    padding: "0px",
                    minWidth: "0px",
                }
            }
        }
    },
    modalFooter: {
        ...modalStyle(theme).modalFooter,
        marginTop: theme.spacing(2),
        justifyContent: "center",
        MsFlexPack: "center",
        WebkitBoxPack: "center",
    }
});
  
export default linkDetailModalStyle;
  