import React from 'react'
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import Button from "../CustomButtons/Button.jsx";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import CustomInput from "../CustomInput/CustomInput";
import Close from "@material-ui/icons/Close";
import LoaderComponent from "../Loader";

import newFolderModalStyle from "../../../assets/jss/user/newFolderModalStyle.jsx";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});
Transition.displayName = "Transition";

const NewFolderModal = class extends React.Component {
    constructor(props){
        super(props);
        this.store = this.props.store;

        this.state = {
            folderName: this.props.folderName
        };

        this.handleDateChange = this.handleDateChange.bind(this);
    }
    handleDateChange(e, name) {
        this.setState({
            [name]: e.target.value
        });
    }
    render() {
        const { classes, saving, confirmationMessage } = this.props;
        const { folderName } = this.state;
        return (
            <Dialog
                classes={{
                    root: classes.modalRoot,
                    paper: classes.modal
                }}
                open={this.props.open}
                TransitionComponent={Transition}
                keepMounted
                aria-labelledby="newFolderModal-modal-slide-title"
                aria-describedby="newFolderModal-modal-slide-description"
            >
                <DialogTitle
                    id="newFolderModal-modal-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                    >
                        <Button
                            simple
                            className={classes.modalCloseButton+" "+classes.modalCloseButtonCustom}
                            key="close"
                            aria-label="Close"
                            onClick={() => this.props.onClose()}
                        >
                            {" "}
                            <Close className={classes.modalClose} />
                        </Button>
                        {
                            saving ?
                                <></>
                            :
                            <h4 className={classes.modalTitle}>{confirmationMessage}</h4> 
                        }
                </DialogTitle>
                <DialogContent
                    id="newFolderModal-modal-slide-description"
                    className={classes.modalBody}
                    >
                        {
                            saving ?
                                <LoaderComponent color="custom" align="center" />
                            :
                            <>
                                <CustomInput
                                    labelText="Folder Name"
                                    id="input-folderName"
                                    success={folderName.length > 0}
                                    error={folderName.length === 0}
                                    value={folderName}
                                    inputProps={{
                                        name: "folderName",
                                        type: "text",
                                        onChange: (e) => this.handleDateChange(e, 'folderName')
                                    }}
                                    formControlProps={{
                                        fullWidth: true,
                                        className: "custom-input"
                                    }}
                                />
                            </>
                        }
                </DialogContent>
                {
                    saving ?
                        <></>
                    :
                    <DialogActions className={classes.modalFooter}>
                        <Button  color="custom" className={classes.changeButton} disabled={(folderName.length === 0)} onClick={() => this.props.onSuccess(folderName)}>
                            Create
                        </Button>
                    </DialogActions>
                }
            </Dialog>
        )
    }
}

NewFolderModal.defaultProps = {
    open: false,
    confirmationMessage: "",
    saving: false,
    folderName: ""
}
NewFolderModal.propTypes = {
    onClose: PropTypes.func,
    open: PropTypes.bool,
    confirmationMessage: PropTypes.string,
    saving: PropTypes.bool
}
export default withStyles(newFolderModalStyle)(NewFolderModal);