import React from 'react'
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import Button from "../CustomButtons/Button.jsx";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Close from "@material-ui/icons/Close";
import LoaderComponent from "../Loader";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Check from "@material-ui/icons/Check";

import change2FAModalStyle from "../../../assets/jss/user/change2FAModalStyle.jsx";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});
Transition.displayName = "Transition";

const Change2FAModal = class extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            enable_2fa: true
        };
    }
    handleCheckbox(e, name){
        let state = {};
        state[name] = e.target.checked;
        this.setState(state);
    }
    render() {
        const { classes, saving, confirmationMessage } = this.props;
        const { enable_2fa } = this.state;
        return (
            <Dialog
                classes={{
                    root: classes.modalRoot,
                    paper: classes.modal
                }}
                open={this.props.open}
                TransitionComponent={Transition}
                keepMounted
                aria-labelledby="confirmationModal-modal-slide-title"
                aria-describedby="confirmationModal-modal-slide-description"
            >
                <DialogTitle
                    id="confirmationModal-modal-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                    >
                        <Button
                            simple
                            className={classes.modalCloseButton+" "+classes.modalCloseButtonCustom}
                            key="close"
                            aria-label="Close"
                            onClick={() => this.props.onClose()}
                        >
                            {" "}
                            <Close className={classes.modalClose} />
                        </Button>
                        {
                            saving ?
                                <></>
                            :
                            <h4 className={classes.modalTitle+" "+classes.textCenter}>{confirmationMessage}</h4> 
                        }
                </DialogTitle>
                <DialogContent
                    id="confirmationModal-modal-slide-description"
                    className={classes.modalBody}
                    >
                        {
                            saving ?
                                <LoaderComponent color="custom" align="center" />
                            :
                            <>
                                <FormControlLabel
                                    classes={{label: classes.label}}
                                    control={
                                        <Checkbox                                                
                                            tabIndex={-1}
                                            onClick={(e) => this.handleCheckbox(e, "enable_2fa")}
                                            checkedIcon={<Check className={classes.checkedIcon} />}
                                            icon={<Check className={classes.uncheckedIcon} />}
                                            classes={{checked: classes.checked, root: classes.checkRoot}}
                                            checked={enable_2fa}
                                        />
                                    }
                                    label={"Enable Two Factor Authentication"}
                                />
                            </>
                        }
                </DialogContent>
                {
                    saving ?
                        <></>
                    :
                    <DialogActions className={classes.modalFooter +" " +classes.justifyContentCenter}>
                        <Button color="custom" className={classes.changeButton} onClick={() => this.props.onSuccess(enable_2fa)}>
                            Change
                        </Button>
                    </DialogActions>
                }
            </Dialog>
        )
    }
}

Change2FAModal.defaultProps = {
    open: false,
    confirmationMessage: "",
    saving: false
}
Change2FAModal.propTypes = {
    onClose: PropTypes.func,
    open: PropTypes.bool,
    confirmationMessage: PropTypes.string,
    saving: PropTypes.bool
}
export default withStyles(change2FAModalStyle)(Change2FAModal);