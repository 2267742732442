import {
    dangerColor, grayColor, whiteColor
} from "../material-kit-pro-react.jsx";
import modalStyle from "../material-kit-pro-react/modalStyle.jsx";
import customCheckboxRadioSwitchStyle from "../material-kit-pro-react/customCheckboxRadioSwitchStyle.jsx";
  
const externalLinkModalStyle = theme => ({
    ...modalStyle(theme),
    ...customCheckboxRadioSwitchStyle,
    textCenter: {
        textAlign: "center"
    },
    justifyContentCenter: {
        WebkitBoxPack: "center !important",
        MsFlexPack: "center !important",
        justifyContent: "center !important",
        alignSelf: "center !important"
    },
    modal: {
        ...modalStyle(theme).modal,
        maxWidth: "400px !important"
    },
    modalBody: {
        ...modalStyle(theme).modalBody,
        paddingBottom: "0px",
        paddingTop: "0px",
        paddingRight: "10px",
        paddingLeft: "10px",
        "& .sd_loader": {
            width: "300px !important",
            height: "300px !important",
            [theme.breakpoints.down("xs")]: {
                width: "180px !important",
                height: "180px !important",
            },
            "& > div": {
                width: "250px !important",
                height: "250px !important",
                [theme.breakpoints.down("xs")]: {
                    width: "150px !important",
                    height: "150px !important",
                },
            },
            "& svg": {
                "& circle": {
                    strokeWidth: "1px"
                }
            }
        },
        "& h4": {
            textAlign: "center",
            marginTop: "0px",
            marginBottom: '15px',
        },
        "& .viewPasswordIcon": {
            cursor: "pointer",
            marginTop: "-4px"
        },
    },
    modalFooter: {
        ...modalStyle(theme).modalFooter,
        marginTop: theme.spacing(2),
        justifyContent: "space-between !important",
        MsFlexPack: "space-between !important",
        WebkitBoxPack: "space-between !important",
    },
    errorMessageDiv: {
        padding: "10px 0px"
    },
    errorMessage: {
        color: dangerColor[0]
    },
    downloadLink: {
        wordBreak: "break-all",
        textAlign: "left",
        "& .share-icon-lists": {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            listStyleType: "none",
            padding: '0px',
            marginBottom: theme.spacing(3),
            "& li": {
                width: "50px",
                height: "50px",
                borderRadius: '100%',
                "& a": {
                    cursor: "pointer"
                },
                "& img": {
                    maxWidth: '50px',
                    width: "100%",
                    display: 'block'
                },
                [theme.breakpoints.down("xs")]: {
                    width: "40px",
                    height: "40px", 
                }
            },
            "& .sendlinx-icon": {
                backgroundColor: "#888787",  
                "& img": {
                    padding: "5px"
                }
            },
        },
        "& .download-link-section": {
            display: 'flex',
            border: '1px solid '+grayColor[2],
            borderRadius: '30px',
            alignItems: 'center',
            justifyContent: 'flex-start',
            padding: '0px 1px',
            "& p": {
                fontSize: '11px',
                fontWeight: 'bold',
                margin: '0px',
                flex: '1',
            },
            "& button": {
                margin: "1px 0px",
                textTransform: 'none',
                padding: '0.3rem 1.0rem',
            },
            '& .icon': {                
                borderRight: "1px solid "+grayColor[17],
                paddingRight: '4px',
                display: 'flex',
                paddingLeft: '4px',
                marginRight: '5px',
                "& svg": {
                    fill: grayColor[17],
                    width: '20px',
                }
            }
        }
    },
    dNone: {
        display: 'none'
    },
    modalCloseButton: {
        "&, &:hover": {
            color: grayColor[0]
        },
        "&:hover": {
            opacity: "1"
        },
        cursor: "pointer",
        padding: "0.5rem",
        margin: "-1rem -1rem -1rem auto",
        backgroundColor: "transparent",
        border: "0",
        WebkitAppearance: "none",
        float: "right",
        fontSize: "1.5rem",
        fontWeight: "500",
        lineHeight: "1",
        textShadow: "0 1px 0 " + whiteColor,
        opacity: ".5",
    },
    modalClose: {
        width: "16px",
        height: "16px"
    },
    modalCloseButtonCustom: {
        top: "16px !important",
        right: "16px !important",
        position: "absolute !important"
    },
    modalTitle: {
        margin: "0",
        lineHeight: "1.5",
        textAlign: "center",
    },
    dateTimePicker: {
        position: "relative",
        "& .MuiFormControl-root.MuiTextField-root": {
            position: "static"
        },
        "& label": {
            "& + .MuiInput-formControl": {
                marginTop: "0px"
            }
        },
        "& .MuiFormHelperText-root": {
            display: "none"
        },
        "& .clear-icon": {
            position: "absolute",
            margin: "0px",
            padding: "0px",
            width: "24px",
            height: "24px",
            minWidth: "auto",
            right: "0px",
            bottom: "22px",
        }
    }
});
  
export default externalLinkModalStyle;
  