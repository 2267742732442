import React from 'react'
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import Button from "../CustomButtons/Button.jsx";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import LoaderComponent from "../Loader.js";
import axios from "axios";
import Api from "../../../assets/js/utils/Api.js";
// import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import { /*ExpandMore,*/ ChevronRight, CheckCircle, ArrowBack, CreateNewFolder, Folder }  from '@material-ui/icons';
import foldericon from "../../../assets/img/foldericon.png";
import { helper } from "../../../assets/js/utils/Element";
import ApiOneDrive from "../../../assets/js/utils/oneDrive/Api.js";
import ApiSharepoint from "../../../assets/js/utils/Sharepoint/Api.js";
import sendlinxIcon from "../../../assets/img/sendlinx-icon.png";
import onedriveIcon from "../../../assets/img/cloudicon.png";

import copyFilesModalStyle from "../../../assets/jss/user/copyFilesModalStyle.jsx";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});
Transition.displayName = "Transition";

const CopyMyDocModal = class extends React.Component {
    constructor(props){
        super(props);
        this.store = this.props.store;

        let path = helper.getParam("dir");
        if(path === null || path === "null"){
            path = "/";
        }
        this.state = {
            newPath: this.props.path,
            path: decodeURIComponent(path.trim()),
            folders: null,
            loading: false,
            countingFiles: false,
            files: this.props.files,
            showLists: true,
            totalSize: this.getTotalSize(this.props.files),
            startTime: null,
            totalSent: 0,
            openFolders: null,
            openFolderPath: this.props.openFolderPath,
            pathExist: true
        }
    }
    componentDidMount(){
        this.loadFolders();
        this.countFoldersChilds();
    }
    componentWillReceiveProps(props){
        this.setState({
            files: props.files
        });
    }
    componentDidUpdate(prevPros, prevState){
        if(this.props.saving === true){
            const notCopiedObjects = this.state.files.filter(obj => !obj.copied);

            if(notCopiedObjects.length <= 0){
                setTimeout(() => {
                    this.props.onClose({addis:false,fPath:null, openMyDocs: true});
                }, 1000);
            }
        }
        if(!prevPros.saving && this.props.saving){
            this.setState({
                startTime: new Date()
            })
        }

        if(!prevPros.canceled && this.props.canceled){
            setTimeout(() => {
                this.props.onClose({addis:false,fPath:null, openMyDocs: false, loadFiles: true});
            }, 1000);
        }
    }
    loadFolders(){
        const source = axios.CancelToken.source();

        let requestData = {
            page: 1,
            limit: 200,
            path: '/My Docs',
            nested_files: 1,
            includeSub: true
        };
        this.setState({
            loading: true
        })
        Api.getFolders(requestData, source).then(data => {
            this.getFolders(data);
            this.setState({ loading: false })
        }).catch(err => {
            this.setState({ loading: false })
        }); 
    }
    countFoldersChilds = async () => {
        const { files } = this.state;
        this.setState({
            countingFiles: true
        })
        try{
            await Promise.all(files.map(async (folder, index) => {
                const data = await this.getAllDataFolderOneDrive(folder.parent_id, folder.id);
                
                if(data === undefined || data === null){
                    folder.countFiles = 0;
                }else{
                    folder.countFiles = data.length;
                }
            }));
        
            this.setState({
                countingFiles: false
            });
        } catch (err) {
            console.log(err);
        }           
    }
    async getAllDataFolderOneDrive(parentId, id){
        const { path } = this.state;
        let apiUrl = ApiOneDrive;
        if(path.includes("/Sharepoint")){
            apiUrl = ApiSharepoint;
        }

        const response = await apiUrl.getDataAllFolderOneDrive(parentId, id).then(data => {
            return data;
        }).catch(err => {
            console.log(err);
        });
        const folderData = response.value;

        if(folderData && folderData.length>0){            
            const childFolders = folderData.filter(item => item.folder);
            const childDataPromises = childFolders.map(folder => {
                return this.getAllDataFolderOneDrive(parentId, folder.id);
            });
            if (childDataPromises.length > 0) {
                const childData = await Promise.all(childDataPromises);
                folderData.push(...childData.flat());
            }
        }
        
        return folderData;
    }
    onNodeSelect(e, value){
        this.setState({
            newPath: value
        });
    }
    getNodeName(nodeName){
        const {  classes } = this.props;
        return (
            <div className={classes.nodeLabel}>
                <img src={foldericon} alt={"folder"} />
                {nodeName}
            </div>
        )
    }
    renderTree(nodes){
        return(
            <TreeItem key={nodes.id} nodeId={nodes.id} label={this.getNodeName(nodes.name)}>
                {Array.isArray(nodes.children) ? nodes.children.map((node) => this.renderTree(node)) : null}
            </TreeItem>
        )
    }
    getFolderItems(data){
        let array = [];
        data.map((folder) => {
            if(folder.type === 'file'){
                return null;
            }
            let obj = {}
            obj.id = folder.path;
            obj.name = folder.name;
            array.push(obj);

            obj['children'] = this.getFolderItems(folder.files);

            return null
        });
        return array;
    }
    getFoldersObj(data){
        let { path } = this.state;
        let obj;
        if(path.includes("/OneDrive") || path.includes("/Sharepoint")){
            // obj = {
            //     'id': '/',
            //     'name': 'My Docs'
            // }
            // obj['children'] = this.getFolderItems(data);
            obj = this.getFolderItems(data);
            if(obj.length > 0){
                obj = obj[0];
            }
        }else{
            obj = {
                'id': '/',
                'name': 'root'
            }
            
            obj['children'] = this.getFolderItems(data);
        }
        // console.log(obj);
        return obj;
    }
    getFolders(data){
        const userData = JSON.parse(localStorage.getItem("sendlinx_userinfo"));

        if(!data.response || data.response.length === 0){
            let { path } = this.state;
            let obj;
            if(path.includes("/OneDrive") || path.includes("/Sharepoint")){
                obj = {
                    'id': '/My Docs',
                    'name': 'My Docs',
                    'children': []
                }
            }else{
                obj = {
                    'id': '/',
                    'name': 'root',
                    'children': []
                }
            }
            this.setState({
                folders: obj,
                loading: false
            });
            return obj;
        }
        const folders = this.getFoldersObj(data.response);
        this.setState({
            folders: folders,
            loading: false
        },() => {
            let isSharePoint = false;
            if(this.state.path.includes("/Sharepoint")){
                isSharePoint = true;
            }
            
            if(this.state.openFolderPath !== null){
                this.toggleFolder(this.state.openFolderPath);
            }else if(!isSharePoint && userData.extensions.one_drive.path !== null){
                let openFolderPath = userData.extensions.one_drive.path;
                openFolderPath = '/My Docs/'+openFolderPath;
                
                let isFound = this.toggleFolder(openFolderPath, true);
                if(!isFound){
                    this.toggleFolder('/', false, false);
                }
            }else if(isSharePoint && userData.extensions.sharepoint.path !== null){
                let openFolderPath = userData.extensions.sharepoint.path;
                openFolderPath = '/My Docs/'+openFolderPath;
                
                let isFound = this.toggleFolder(openFolderPath, true);
                if(!isFound){
                    this.toggleFolder('/', false, false);
                }
            }
        });
    }
    getDefaultExpanded(){
        var pathArray = this.props.path.split("/");
        pathArray = pathArray.map(i => '/' + i);

        let pathStringArray = [];
        let previousPath = "";
        pathArray.map((path, key) => {
            if(key <= 1){
                pathStringArray[key] = path;
            }else{
                pathStringArray[key] = previousPath+""+path;
            }
            previousPath = pathStringArray[key];

            return null;
        })

        return pathStringArray;
    }
    handleToggleFiles(){
        this.setState({
            showLists: !this.state.showLists
        })
    }
    getTotalSize(files){
        if(files === null){
            files = this.state.files;
        }
        let totalSize = 0;
        totalSize = files.reduce((accumulator, obj) => accumulator + obj.orignalSize, 0);
        
        return totalSize;
    }
    formatEstimatedTime(expectedTime){
        if(expectedTime <= 2){
            return "Few Seconds";
        }
        if(expectedTime < 60){
            expectedTime = Math.trunc(expectedTime);
            return expectedTime+" Seconds";
        }
        let minutes = expectedTime/60;
        if(minutes < 60){
            const seconds = Math.round(expectedTime%60);
            minutes = Math.trunc(minutes);
            return (minutes+" Minutes"+(seconds ? (" "+seconds+" Seconds") : ""));
        }
        expectedTime = expectedTime/60;
        let hours = expectedTime/60;
        minutes = Math.round(expectedTime%60);
        hours = Math.trunc(hours);
        return (hours+" Hours"+(minutes ? (" "+minutes+" Minutes") : ""));
    }
    getEstimatedTimeLeft(){
        const { startTime, totalSize } = this.state;
        const { filesCopiedSize } = this.props;

        const currentTime = new Date();
        const timeElapsed = (currentTime - startTime)/1000;
        const totalUploaded = filesCopiedSize;
        if(totalUploaded <= 0){
            return 'Estimating...';
        }
        let uploadRate = (totalUploaded/timeElapsed);
        const expectedTime = (totalSize - totalUploaded)/uploadRate;
        const timeEstimate = this.formatEstimatedTime(expectedTime);
        
        return timeEstimate+' left...';
    }
    getPathStructure(){
        const { newPath } = this.state;
        if(this.props.path === newPath){
            return 'Select folder to show path';
        }
        if(newPath === '/'){
            return <span onClick={() => this.toggleFolder('/')}>
                My Docs
            </span>
        }
        
        const parts = newPath.split('/').filter(part => part.trim() !== '');
        const pathStructure = parts.map((part, index) => {
            let currentPath = '/'+parts.slice(0, index + 1).join('/');
            currentPath = currentPath === '/My Docs' ? '/' : currentPath;
            return (
                <span key={index} onClick={() => this.toggleFolder(currentPath)}>
                    {part}
                </span>
            );
        });

        return pathStructure;
    }
    getChildrenById(obj, targetId) {
        if(targetId === '/'){
            targetId = '/My Docs'
        }

        if (obj.id === targetId) {
          return obj.children;
        } else if (obj.children) {
          for (const child of obj.children) {
            const result = this.getChildrenById(child, targetId);
            if (result) {
              return result;
            }
          }
        }
        return null;
    }
    togglePath(folderId){
        if(folderId === this.state.newPath){
            const pathArray = folderId.split('/');
            pathArray.pop();
            let updatedPath = pathArray.join("/");
            if(updatedPath === ''){
                updatedPath = this.props.path;
            }
            folderId = updatedPath;
        }
        this.setState({
            newPath: folderId,
        });
    }
    toggleFolder(folderId, checkPathExist = false, updatePathExistState = true) {        
        const { folders } = this.state;
        let openFolders = this.getChildrenById(folders, folderId);
        let openFolderPath = folderId;
        if(this.props.path === folderId){
            openFolderPath = null;
        }

        let pathExist = true;
        if(checkPathExist && openFolders === null){
            pathExist = false;
        }

        let state = {
            openFolders: openFolders,
            newPath: folderId,
            openFolderPath: openFolderPath,
        };
        if(updatePathExistState){
            state['pathExist'] = pathExist;
        }
        this.setState(state);

        if(checkPathExist){
            return pathExist;
        }
    }
    renderFolder() {
        let { openFolders, newPath } = this.state;

        return (
            <>
                {
                    openFolders === null ?
                        <div className={'sd-folder-directory '+('/' === newPath ? 'selected' : '')}>
                            <div className='folder-name' onClick={() => this.togglePath('/')} onDoubleClick={() => this.toggleFolder('/')}>
                                <Folder />
                                <span>My Docs</span>
                            </div>
                            <div className='folder-action'>
                                <span onClick={() => this.props.onSuccess('/')} className='copy'>Copy to</span>
                                <span onClick={() => this.toggleFolder('/')}><ChevronRight /></span>
                            </div>
                        </div>
                    : openFolders.length > 0 ?
                        openFolders.map((folder, key) => {
                            return(
                                <div className={'sd-folder-directory '+(folder.id === newPath ? 'selected' : '')} key={key}>
                                    <div className='folder-name' onClick={() => this.togglePath(folder.id)} onDoubleClick={() => this.toggleFolder(folder.id)}>
                                        <Folder />
                                        <span>{folder.name}</span>
                                    </div>
                                    <div className='folder-action'>
                                        <span onClick={() => this.props.onSuccess(folder.id)} className='copy'>Copy to</span>
                                        <span onClick={() => this.toggleFolder(folder.id)}><ChevronRight /></span>
                                    </div>
                                </div>
                            )
                        })
                    :
                        <div className='empty-folder'><p>This folder is empty</p></div>
                }
            </>
        );
    }
    getBackPath(){
        const { openFolders, openFolderPath } = this.state;

        if(openFolders === null){
            return <></>;
        }else if(openFolderPath === '/'){
            return (
                <>
                    <Button color="transparent" className="sd-back-icon 1" onClick={() => this.toggleFolder(this.props.path)} justIcon round><ArrowBack /></Button>My Docs
                </>
            )
        }else{
            const pathArray = openFolderPath.split('/');
            const lastPath = pathArray[pathArray.length - 1];
            pathArray.pop();
            let updatedPath = pathArray.join("/");
            if(updatedPath === '/My Docs'){
                updatedPath = '/';
            }
            return (
                <>
                    <Button color="transparent" className="sd-back-icon 2" onClick={() => this.toggleFolder(updatedPath)} justIcon round><ArrowBack /></Button>{lastPath}
                </>
            )
        }
    }
    getCopiesCountTitle(){
        const { files } = this.state;

        let countTitle = '';
        if(files.length > 1){
            countTitle = files.length+' items';
        }else if(files.length === 1){
            files.map(file => {
                countTitle = file.name;

                return null;
            })
        }
        return 'Copy '+countTitle;
    }
    copyFilesProgress(){
        const { classes, canceled } = this.props;
        const { showLists, files } = this.state;
        let timeLeft = this.getEstimatedTimeLeft();

        return (
            <div className={classes.copyFilesProgress}>
                <p className='copied-files-header' onClick={() => this.handleToggleFiles()}>Copying {files.length}{files.length > 1 ? ' Items' : ' Item'} to My Docs...</p>
                {
                    !canceled ?
                        <div className={'copied-files-detail '+(showLists ? 'show' : 'hide')}>
                            <p>{timeLeft}</p>
                            <p className='cancel' onClick={() => this.props.onCancel()}>Cancel</p>
                        </div>
                    :
                        <></>
                }
                <div className={'files-listings '+(showLists ? 'show' : 'hide')}>
                    <ul>
                        {
                            files.map((file, key) => {
                                return (
                                    <li key={key}>
                                        <div className='file-info'>
                                            {
                                                file.type === 'folder' ?
                                                    <Button justIcon color="transparent" className={classes.fileIcon}>
                                                        <img src={foldericon} alt={"folder"} />
                                                    </Button>
                                                :
                                                    helper.getFileIcon(file, classes)
                                            }
                                            <span className='name' title={file.name}>{file.name}</span>
                                            {
                                                file.countFiles > 0 || file.countFiles > '0' ?
                                                    <span className='count'>{file.hasOwnProperty('countCopiedFiles') ? file.countCopiedFiles : '0'} of {file.countFiles}</span>
                                                :
                                                    <></>
                                            }
                                        </div>
                                        {
                                            file.hasOwnProperty('copied') && file.copied ?
                                                <CheckCircle className={classes.icons}/>
                                            : !canceled ?
                                                <LoaderComponent color="custom" align="center" />
                                            :
                                                <></>
                                        }
                                    </li>
                                )
                            })
                        }
                    </ul>
                </div>
                
            </div>
        );
    }
    render() {
        const { classes, path, errorMessage,leftMemorySize, saving, loadingMessage } = this.props;
        const { newPath, folders, loading, files, countingFiles, openFolderPath, pathExist } = this.state;
        let isSharePoint = false;
        if(this.state.path.includes("/Sharepoint")){
            isSharePoint = true;
        }

        // const totalFileSize = files.reduce((total, file) => total + file.size, 0);
        const totalFileSizeInMB = files.reduce((total, file) => {
            const matches = file.size.match(/([\d.]+)\s*(mb|kb)/i);
            if (matches) {
              const value = parseFloat(matches[1]);
              const unit = matches[2].toLowerCase();
              if (unit === 'mb') {
                return total + value;
              } else if (unit === 'kb') {
                return total + (value / 1024);
              }
            }
            return total;
        }, 0);
        
        if(saving){
            return this.copyFilesProgress()
        }

        return (
            <Dialog
                classes={{
                    root: classes.modalRoot,
                    paper: classes.modal
                }}
                open={this.props.open}
                TransitionComponent={Transition}
                keepMounted
                aria-labelledby="copyFilesModal-slide-title"
                aria-describedby="copyFilesModal-slide-description"
            >
                <DialogTitle
                    id="copyFilesModal-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                    >
                        {
                            loading || countingFiles ?
                                <></>
                            :
                                errorMessage !== null ?
                                    <h4 className={classes.modalTitle+" "+classes.textCenter}>{errorMessage}</h4>
                                : saving ?
                                    <h4 className={classes.modalTitle+" "+classes.textCenter}>{loadingMessage}</h4> 
                                :
                                    <h4 className={classes.modalTitle+" "+classes.filesCountTitle}>{this.getCopiesCountTitle()}</h4> 
                        }
                </DialogTitle>
                <DialogContent
                    id="copyFilesModal-slide-description"
                    className={classes.modalBody}
                    >
                        {
                             saving || loading || countingFiles  ?
                                <LoaderComponent color="custom" align="center" />
                            : errorMessage !== null ?
                                <></>
                            :
                                <>
                                    <div className='from-content'>
                                        <p className='label'>From</p>
                                        <div className='from-name'>
                                            <img src={onedriveIcon} alt="onedrive-icon" />
                                            {
                                                isSharePoint ?
                                                    <>Sharepoint</>
                                                :
                                                    <>OneDrive</>
                                            }
                                        </div>
                                        <p className='email'>{localStorage.getItem("one_drive_email")}</p>
                                    </div> 
                                    <div className='from-content'>
                                        <p className='label'>To</p>
                                        <div className='from-name'>
                                            <img src={sendlinxIcon} alt="sendlinx-icon" />
                                            Sendlinx
                                        </div>
                                        <p className='path'>{this.getPathStructure()}</p>
                                    </div> 
                                    {
                                        !pathExist ? 
                                            <div className='folder-exist-note'>
                                                <p>The default destination path is no longer available. The new default path will be "My Docs".</p>
                                            </div>
                                        :
                                            <></>
                                    }
                                    {
                                        totalFileSizeInMB > leftMemorySize && (
                                            <>
                                                <p>Total File Size: {totalFileSizeInMB}MB</p>
                                                <p>Available Space: {leftMemorySize}MB</p>
                                                <p style={{ color: 'red' }}>Not enough space in memory</p>
                                            </>
                                        )
                                    }
                                    <div className='sd-back-newfolder'>
                                        <div className='sd-back-path'>{this.getBackPath()}</div>
                                        <Button color="transparent" onClick={() => this.props.onClose({addis:true,fPath:openFolderPath})} disabled={(path === newPath || openFolderPath === null)} justIcon round><CreateNewFolder /></Button>
                                    </div>
                                    {
                                        folders ?
                                            <>
                                                {/* <div className={classes.treeView}>
                                                    <TreeView
                                                        className={classes.root}
                                                        defaultCollapseIcon={<ExpandMore />}
                                                        defaultExpanded={this.getDefaultExpanded()}
                                                        defaultExpandIcon={<ChevronRight />}
                                                        onNodeSelect={(event, value) => this.onNodeSelect(event, value)}
                                                        defaultSelected={path}
                                                    >
                                                        {this.renderTree(folders)}
                                                    </TreeView>
                                                </div> */}
                                                <div className={classes.foldersDirectory}>{this.renderFolder()}</div>
                                            </>
                                        :
                                            <></>
                                    }
                                </>
                        }
                </DialogContent>
                <DialogActions className={classes.modalFooter +" " +classes.justifyContentCenter}>
                    {
                        loading || countingFiles ?
                            <></>
                        :
                            errorMessage !== null ?
                                <Button color="custom" onClick={() => this.props.onClose({addis:false,fPath:null})} round>Close</Button>
                            : saving === false ?
                                <>
                                    {
                                        totalFileSizeInMB < leftMemorySize && (
                                            <>
                                                <Button color="custom" onClick={() => this.props.onSuccess(newPath)} disabled={(path === newPath)} round>Copy File(s)</Button>
                                            </>
                                        )
                                    }
                                    <Button color="white" onClick={() => this.props.onClose({addis:false,fPath:null})} round>Cancel</Button>
                                </>
                            :
                                <></>
                    }
                </DialogActions>
            </Dialog>
        )
    }
}

CopyMyDocModal.defaultProps = {
    open: false,
    loadingMessage: "",
    successMessage: "",
    saving: false,
    canceled: false,
}
CopyMyDocModal.propTypes = {
    onClose: PropTypes.func,
    open: PropTypes.bool,
    loadingMessage: PropTypes.string,
    saving: PropTypes.bool,
    canceled: PropTypes.bool
}
export default withStyles(copyFilesModalStyle)(CopyMyDocModal);