import React from 'react';
import { Redirect } from 'react-router-dom';
import axios from "axios";
import { helper } from '../../../assets/js/utils/Element';
import Config from "../../../../Config";
import Api from "../../../assets/js/utils/Api";
import LoaderComponent from '../../components/Loader'
import withStyles from "@material-ui/core/styles/withStyles";
import CustomInput from "../../components/CustomInput/CustomInput.jsx";
import GridItem from "../../components/Grid/GridItem.jsx";
import GridContainer from "../../components/Grid/GridContainer.jsx";
import Button from "../../components/CustomButtons/Button.jsx";
import Card from "../../components/Card/Card.jsx";
import CardBody from "../../components/Card/CardBody.jsx";
// import InfoArea from "../../components/InfoArea/InfoArea.jsx";
import InputAdornment from "@material-ui/core/InputAdornment";
import CustomSelect from "../../components/CustomInput/CustomSelect";
import BackgroundSlider from "../../components/Slider/BackgroundSlider";

import Email from "@material-ui/icons/Email";
import EditIcon from "@material-ui/icons/Edit";
import RecaptchaV2 from "../../components/Google/RecaptchaV2";

import signupPageStyle from "../../../assets/jss/material-kit-pro-react/views/signupPageStyle.jsx";

const SignupRequest = class extends React.Component {
    constructor(props){
        super(props);

        this.store = this.props.store;
        this.history = this.props.history;
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

        let planId = helper.getParam("id");
        if(planId === null || planId === "null"){
            planId = "1";
        }
        this.state = {
            firstName: '',
            lastName: '',
            email: "",
            phone: "",
            companyName: "",
            accountType: planId,
            businessAccounts: "",
            successMessage: "",
            loading: false,
            validation: {
                firstName: '',
                lastName: '',
                email: '',
                accountType: '',
                isValid: false
            },
            recaptchaToken: "",
            showError: false,
            errorMessage: "",
            showRecaptcha: true,
        };

        this.accountTypes = [
            {
                id: 1,
                label: "Promotion",
            },
            {
                id: 2,
                label: "Standard",
            },
            {
                id: 3,
                label: "Pro",
            },
            {
                id: 4,
                label: "Business",
            }
        ];
    }
    componentDidMount(){

    }
    componentWillUnmount(){
        if (this.cancelToken) {
            this.cancelToken.cancel('Request Cancelled')
        }
    }
    handleChange(e, name){
        let state = {};
        state[name] = e.target.value;
        let limit = 64;
        if(name === "email"){
            limit = 128;
        }
        if(typeof(state[name]) === "string"){
            state[name] = state[name].substr(0, limit);
        }
        if(name === "email"){
            state[name] = state[name].toLowerCase();
        }

        this.setState(state);
    }
    handleDateChange(date, name) {
        try{
            let parsedDate = new Date(date);
            if(parsedDate === "Invalid Date"){
                return;
            }
            this.setState({
                [name]: parsedDate.toISOString()
           });
        }catch(e){ console.log(e); }        
    }
    handleCheckbox(e, name){
        let state = {};
        state[name] = e.target.checked;
        this.setState(state);
    }
    handleSubmit(e){
        e.preventDefault();
        const isValid = this.validateForm();
        if(!isValid || this.state.loading){
            return;
        }
        let values = this.state;
        const { recaptchaToken } = this.state;
        const requestData = {
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            phone: values.phone,
            companyName: values.companyName,
            accountType: values.accountType,
            recaptchaToken: recaptchaToken
        };
        if(values.accountType === 4){
            requestData['businessAccounts'] = values.businessAccounts;
        }
        const source = axios.CancelToken.source();
        Api.signupRequest(requestData, source).then(data => {
            this.setState({
                loading: false, 
                successMessage: data.message
            });
        }).catch(err => {
            if(typeof(err) === "object" && err.hasOwnProperty("message")){
                this.setState({
                    loading: false, 
                    showError: true, 
                    cancelToken: null,
                    errorMessage: err.message,
                });
            }
        });
        this.setState({loading: false, showError: false, cancelToken: source});
    }
    validateForm(){
        const { firstName, lastName, email, accountType, recaptchaToken } = this.state;

        let validation = {
            firstName: "success",
            lastName: "success",
            email: "success",
            accountType: "success",
            isValid: true
        };

        if(firstName.length <= 0){
            validation.firstName = "error";
            validation.isValid = false;
        }
        if(lastName.length <= 0){
            validation.lastName = "error";
            validation.isValid = false;
        }

        var emailRegex = Config.getEmailRegex();
        if(email.length <= 0 || !emailRegex.test(email)){
            validation.email = "error";
            validation.isValid = false;
        }

        if(accountType.length <= 0){
            validation.accountType = "error";
            validation.isValid = false;
        }

        if(recaptchaToken.length <= 0){
            validation.isValid = false;
        }

        this.setState({validation: validation});
        return validation.isValid;
    }
    renderErrorMessages(){
        const { errorMessage } = this.state;
        if(typeof(errorMessage) === "object"){
            let errorMessages = [];
            let key = 0;
            for(const attrib in errorMessage){
                const message = errorMessage[attrib];
                errorMessages.push(<GridItem key={key} className={"passwordCheck-notValid-customizable"}>
                    <span aria-hidden="true" className="validation-error-symbol check-lowerletter">&#x2716;</span>
                    <span className="checkPasswordText-lowerletter">{message}</span>
                </GridItem>);
                key++;
            }
            return errorMessages;
        }
        return <GridItem className={"passwordCheck-notValid-customizable"}>
            <span aria-hidden="true" className="validation-error-symbol check-lowerletter">&#x2716;</span>
            <span className="checkPasswordText-lowerletter">{errorMessage}</span>
        </GridItem>;
    }
    onRecaptcha(token){
        this.setState({
            showRecaptcha: false,
            recaptchaToken: token
        });
    }
    renderPersonalSignup(){
        const { classes } = this.props;
        const { validation } = this.state;
        const { showRecaptcha } = this.state;

        return (
            <>
                <CustomInput
                    formControlProps={{
                        fullWidth: true,
                        className: classes.customFormControlClasses
                    }}
                    success={validation.firstName === "success"}
                    error={validation.firstName === "error"}
                    id="input-firstName"
                    inputProps={{
                        onChange: (e) => this.handleChange(e, 'firstName'),
                        name: "firstName",
                        value: this.state.firstName,
                        startAdornment: (
                            <InputAdornment position="start" className={classes.inputAdornment} >
                                <EditIcon className={classes.inputAdornmentIcon}/>
                            </InputAdornment>
                        ),
                        placeholder:"First Name"
                    }}
                />
                <CustomInput
                    formControlProps={{
                        fullWidth: true,
                        className: classes.customFormControlClasses
                    }}
                    success={validation.lastName === "success"}
                    error={validation.lastName === "error"}
                    id="input-lastName"
                    inputProps={{
                        onChange: (e) => this.handleChange(e, 'lastName'),
                        name: "lastName",
                        value: this.state.lastName,
                        startAdornment: (
                            <InputAdornment position="start" className={classes.inputAdornment} >
                                <EditIcon className={classes.inputAdornmentIcon}/>
                            </InputAdornment>
                        ),
                        placeholder:"Last Name"
                    }}
                />
                <CustomInput
                    formControlProps={{
                        fullWidth: true,
                        className: classes.customFormControlClasses
                    }}
                    success={validation.email === "success"}
                    error={validation.email === "error"}
                    id="input-email"
                    inputProps={{
                        onChange: (e) => this.handleChange(e, 'email'),
                        name: "email",
                        value: this.state.email,
                        startAdornment: (
                            <InputAdornment position="start" className={classes.inputAdornment} >
                                <Email className={classes.inputAdornmentIcon}/>
                            </InputAdornment>
                        ),
                        placeholder:"Email",
                        type: "email"
                    }}
                />            
                <CustomInput
                    formControlProps={{
                        fullWidth: true,
                        className: classes.customFormControlClasses
                    }}
                    id="input-phone"
                    inputProps={{
                        onChange: (e) => this.handleChange(e, 'phone'),
                        name: "phone",
                        value: this.state.phone,
                        startAdornment: (
                            <InputAdornment position="start" className={classes.inputAdornment} >
                                <EditIcon className={classes.inputAdornmentIcon}/>
                            </InputAdornment>
                        ),
                        placeholder:"Phone"
                    }}
                />
                <CustomInput
                    formControlProps={{
                        fullWidth: true,
                        className: classes.customFormControlClasses
                    }}
                    id="input-companyName"
                    inputProps={{
                        onChange: (e) => this.handleChange(e, 'companyName'),
                        name: "companyName",
                        value: this.state.companyName,
                        startAdornment: (
                            <InputAdornment position="start" className={classes.inputAdornment} >
                                <EditIcon className={classes.inputAdornmentIcon}/>
                            </InputAdornment>
                        ),
                        placeholder:"Company Name"
                    }}
                />
                <CustomSelect 
                    formControlProps={{
                        fullWidth: true
                    }}
                    success={validation.accountType === "success"}
                    error={validation.accountType === "error"}
                    labelText="Interested Plan"
                    selectProps={{
                        onChange: (e) => this.handleChange(e,"accountType"),
                        value: this.state.accountType
                    }}
                    inputProps={{
                        name: "accountType",
                        id: "input-accountType",
                        className: classes.alignLeft
                    }}
                    items={this.accountTypes}
                    itemLabel="label"
                    itemValue="id"
                />
                {
                    this.state.accountType === 4 ?
                        <CustomInput
                            formControlProps={{
                                fullWidth: true,
                                className: classes.customFormControlClasses
                            }}
                            id="input-businessAccounts"
                            inputProps={{
                                onChange: (e) => this.handleChange(e, 'businessAccounts'),
                                name: "businessAccounts",
                                value: this.state.businessAccounts,
                                startAdornment: (
                                    <InputAdornment position="start" className={classes.inputAdornment} >
                                        <EditIcon className={classes.inputAdornmentIcon}/>
                                    </InputAdornment>
                                ),
                                placeholder:"Total Buiness Accounts"
                            }}
                        />
                    :
                        <></>
                }
                <div className={classes.recaptcha}>
                    <RecaptchaV2 onChange={(response) => this.onRecaptcha(response)} show={showRecaptcha} />
                </div>
            </>
        )
        
    }
    render() {
        const { classes } = this.props;
        const { loading, showError, successMessage } = this.state;

        const { authorized } = this.store.getState();
        if(authorized){
            return <Redirect to='/' />
        }
        return (
            <div>
                <div className={classes.container}>
                    <GridContainer justify="center">
                    <GridItem xs={12} sm={10} md={6}>
                        <Card className={classes.cardSignup}>
                        <h2 className={classes.cardTitle}>
                            Sign up Request
                        </h2>
                        <CardBody>
                            <GridContainer justify="center">
                                <GridItem xs={12} sm={12} md={12}>
                                    {
                                        showError ?
                                            this.renderErrorMessages()
                                        :
                                            <></>
                                    }
                                    <form className={classes.form}>
                                        {
                                            loading === false && successMessage !== '' ? 
                                                successMessage
                                            :
                                                this.renderPersonalSignup()
                                        }
                                        <div className={classes.textCenter}>
                                            {
                                                loading ?
                                                    <LoaderComponent />
                                                : 
                                                    successMessage !== '' ?
                                                        <></>
                                                    :
                                                        <Button round color="primary" onClick={this.handleSubmit}>Signup</Button>
                                            }                                        
                                        </div>
                                    </form>
                                </GridItem>
                            </GridContainer>
                        </CardBody>
                        </Card>
                    </GridItem>
                    </GridContainer>
                </div>
                <BackgroundSlider store={this.store} />
            </div>
        )
    }
}

export default withStyles(signupPageStyle)(SignupRequest);